import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
    Card,
    Collapse,
    Divider,
} from "@mui/material";
import {
    Menu as MenuIcon,
    AccountCircle,
    Dvr,
    ExpandMore,
    LockOpen,
    Input,
    ContactPage,
    FilterAlt,
    FileUpload,
    Category
} from "@mui/icons-material";
import React from "react";

class StartView extends React.Component {
    constructor() {
        super();
        this.state = {
        };

    }

    render() {
        return (
            <Container class="subView" >

            <Container sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "30vh"}}>
                <Typography variant="h3">
                    Welcome to the Data Mesh Showcase!
                </Typography> 


                    

            </Container>

            <Container sx={{display: "flex", justifyContent: "center", alignItems: "center", flexdirection: "row"}}>

                <Button
                    sx={{
                        margin: 2,
                        height:200,
                        width:300,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        //border: "3px solid #353535",
                        background: "#1e1e1e",
                    }}
                    margin="normal"
                    onClick={() => {
                        this.props.mainView.setState({ page: "dataProduct" });
                    }}
                >
                    <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Dvr sx={{ fontSize:"100px"}}/>
                        <Typography sx={{display: "flex", textAlign:"center"}}>
                            Data Product Catalog
                        </Typography>
                    </Container>

                </Button>

                <Button
                    sx={{
                        margin: 2,
                        height:200,
                        width:300,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        background: "#1e1e1e",
                    }}
                    margin="normal"
                    onClick={() => {
                        this.props.mainView.setState({ page: "dataProvision" });
                    }}
                >
                    <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FileUpload sx={{ fontSize:"100px"}}/>
                        <Typography sx={{display: "flex", textAlign:"center"}}>
                            Data Product Creation
                        </Typography>
                    </Container>

                </Button>

                <Button
                    sx={{
                        margin: 2,
                        height:200,
                        width:300,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        background: "#1e1e1e",
                    }}
                    margin="normal"
                    onClick={() => {
                        this.props.mainView.setState({ page: "dataProductManagement" });
                    }}
                > 
                    <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Category sx={{ fontSize:"100px"}}/>
                        <Typography sx={{display: "flex", textAlign:"center"}}>
                            My Data Products
                        </Typography>
                    </Container>

                </Button>

            </Container>

            </Container>
        )
    }

}


export default StartView;