import React from "react";
import { Typography, Divider, Container, IconButton } from "@mui/material";
import { Category, Loop } from "@mui/icons-material";

import ReactLoading from "react-loading";

import MyDataProductCard from "../components/MyDataProductCard";

class DataProductManagementView extends React.Component {
    constructor() {
        super();
        this.state = {
            dataProducts: [],
            detailProduct: undefined,
        };
    }

    componentDidMount() {
        this.getDataProducts();
    }

    getDataProducts = () => {
        this.setState({
            ...this.state,
            ...{ dataProducts: [], loadDataProducts: true },
        });

        // TODO: separate in service script
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/catalog/list-data-products`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                console.log(JSON.parse(json.body)[0]);
                this.setState({
                    ...this.state,
                    ...{
                        dataProducts: JSON.parse(json.body),
                        loadDataProducts: false,
                    },
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        return (
            <Container class="subView" >
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                >
                    <Container sx={{ padding: "0 !important" }}>
                        <Container sx={{ padding: "0 !important", display: "flex", flexDirection: "row", marginBottom: 2}}>
                            <Category sx={{ color: "primary.100", marginRight: "20px" }} />
                            <Typography variant="h5">Data Product Management</Typography>
                        </Container>
                        <Typography>
                            Effective management of Data Products is essential for maintaining and operating
                            provisioned Data Products sustainably. It also plays a crucial role
                             in engineering new Data Products during the development stage.
                        </Typography>
                    </Container>
                </Container>
                <Divider sx={{ marginBottom: 5 }} />
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">My Data Products</Typography>
                    <IconButton onClick={this.getDataProducts}>
                        <Loop />
                    </IconButton>
                </Container>

                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {this.state.loadDataProducts && <ReactLoading type={"cylon"} />}

                    {this.state.dataProducts
                        .filter((x) => x.Parameters && x.Parameters.owner === this.props.user)
                        .map((x) => (
                            <MyDataProductCard details={x} token={this.props.token} access_token={this.props.access_token} />
                        ))}
                </Container>
            </Container>
        );
    }
}

export default DataProductManagementView;
