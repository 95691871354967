/**
 * This function send the post request to the server to create a data product.
 * @param {object} data
 * @param {str} token
 */
const createDataProduct = (data, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-data-product`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({ body: data }),
        })
            .then((res) => res.json())
            .then((json) => {
                // TODO: Handle return value, e.g. getting the status from the glue crawler here?
                console.log(json);
            })
            .catch(function (error) {
                console.log(error);
            });
    });
};

/**
 * This function returns the current status of the glue crawler for a specific data product
 * @param {*} dataProduct
 */
const getCatalogCrawlerStatus = (dataProduct) => {
    // TODO: Implement.
    throw new Error("Not Implemented!");
};

/**
 * This function triggers the consumeDataProduct endpoint and handovers the selected interface
 * that should be used to access the DP. I.e. 'interface' contains for example 'file_download'
 * and the switch-case triggers the corresponding function.
 * @param {*} dataProduct dataProduct to be consumed
 * @param {*} consumptionInterface The interface which should be use to access the data product.
 * @param {*} token auth token for the aws api gateway
 */
const accessData = (dataProduct, consumptionInterface, token) => {
    switch (consumptionInterface) {
        case "file_download":
            start_file_download(dataProduct, token);
            break;
        case "rest_api":
            break;
        case "presto_jupyter":
            break;
        case "jupyter_notebook":
            break;
        default:
            break;
    }
};

/**
 * Get a list of all notebooks for the given DataProduct, User and Workgroup from an AWS Lambda *
 * @param {*} ref Reference to the caller (DataProductAccessMenu)
 * @param {*} username User of the notebooks
 * @param {*} dataProduct DataProduct of the nootebooks
 * @param {*} workgroup Workgroup of the notebooks
 * @param {*} token aws auth token
 */
async function getNotebookList(refFunc, username, dataProduct, workgroup, token) {
    if (!username) {
        return;
    }

    fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-notebook-list`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({ dp_name: dataProduct.Name, user_name: username, work_group: workgroup }),
    })
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if (result.statusCode == 200) {
                refFunc(JSON.parse(result.body));
            } else if (result.statusCode == 500) {
                console.log(result);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

/**
 * Call a AWS Lambda that return a presigned URL to the given notebook,
 * then opens that notebook in a new tab.
 * @param {*} notebook The notebook to be opend
 * @param {*} dataProduct The DataProduct of that notebook
 * @param {*} username the user that own that notebook
 * @param {*} workgroup the workgroup that notebook is in
 * @param {*} token aws auth token
 */
const openNotebook = (notebook, dataProduct, username, workgroup, token) => {
    if (!username) {
        return;
    }
    fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-notebook-url`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({
            work_group: workgroup,
            notebook_id: notebook.id,
            dp_name: dataProduct.Name,
            user_name: username,
        }),
    })
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if (result.statusCode == 200) {
                const notebookUrl = JSON.parse(result.body).notebook_url;
                window.open(notebookUrl);
            } else if (result.statusCode == 500) {
                console.log(result);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

/**
 * Call a AWS Lambda that creates a new Athena-Notebook
 * @param {*} ref Referenc to the caller(DataProductAccessMenu)
 * @param {*} username User that wants to create that notebook
 * @param {*} users Comma seperated list of additional users for that notebook
 * @param {*} dataProduct The DataProduct of that notebook
 * @param {*} notebookname The custom name / suffix of that notebook
 * @param {*} workgroup The workgroup that notebook should be created in
 * @param {*} token aws auth token
 */
const createNewNotebook = (refFunc, username, users, dataProduct, notebookname, workgroup, token) => {
    if (!username || !notebookname) {
        return;
    }

    fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-new-notebook`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({
            work_group: workgroup,
            notebook_name: notebookname,
            dp_name: dataProduct.Name,
            user_name: username,
            users: users,
        }),
    })
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if (result.statusCode == 200) {
                console.log(result);
                refFunc();
            } else if (result.statusCode == 500) {
                console.log(result);
                alert("Notebook creation failed!");
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

/**
 * This function creates an API request for the DataProduct, which returns a URL
 * to download the file of that DataProduct directly in the browser
 * @param {*} dataProduct  dataProduct to be consumed
 * @param {*} token auth token for the aws api gateway
 */
const start_file_download = (dataProduct, token) => {
    fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/data_product_download_file`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({ dp_name: dataProduct.Name, source_uri: dataProduct.StorageDescriptor.Location }),
    })
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if (result.statusCode == 200) {
                const fileUrl = JSON.parse(result.body).file_url;
                downloadURI(fileUrl);
            } else if (result.statusCode == 500) {
                console.log(result);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

/**
 * Downloads a file from the given URI without opening a new tab
 * @param {*} uri The Uri of the file to download
 */
function downloadURI(uri) {
    var link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

async function uploadFile(ref_updateDataSourceS3URI, nameDataSource, files, dataProductName, username, token) {
    //Get S3 URL From API/Lambda
    let response = await fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-s3-upload-url`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({
            dp_name: dataProductName,
            user_name: username,
            file_name: files[0].name.toLowerCase(),
            file_type: files[0].type,
        }),
    })
        .then((response) => {
            if (response.ok) {
                //console.log(response);
                var res = response.json();
                return res;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    var s3_url = JSON.parse(response.body).url;
    //console.log(s3_url);

    var s3_uri = JSON.parse(response.body).s3_uri;
    //console.log(s3_uri);
    ref_updateDataSourceS3URI(nameDataSource, s3_uri);

    var file = files[0];

    //upload file to s3

    const response_upload = await fetch(s3_url, {
        method: "PUT",
        body: file,
    });
}

async function getDataHead(dataProduct, token, refFunc) {
    fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/data-product-get-data-head`, {
        method: "POST",
        //mode: 'no-cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            degheader: token,
        },
        body: JSON.stringify({ dp_name: dataProduct.Name }),
    })
        .then(function (response) {
            console.log(response)
            return response.json();
        })
        .then((result) => {
            if (result.statusCode == 200) {
                const header = JSON.parse(result.body).rows_header;
                const data = JSON.parse(result.body).rows_data;
                console.log(header);
                console.log(data);
                refFunc(header, data);
            } else if (result.statusCode == 500) {
                console.log(result);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

const saveDataProductSchema = (data, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-data-product/save-schema`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({ body: data }),
        })
            .then((res) => res.json())
            .then((json) => {
                onSuccess(json);
            })
            .catch(function (error) {
                console.log(error);
            });
    });
};

const getDataProductSchema = (data, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/catalog/get-schema`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({ body: data }),
        })
            .then((res) => res.json())
            .then((json) => onSuccess(json))
            .catch(function (error) {
                console.log(error);
            });
    });
};

const applyDataProductSchema = (data, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-data-product/apply-schema`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({ body: data }),
        })
            .then((res) => res.json())
            .then((json) => onSuccess(json))
            .catch(function (error) {
                console.log(error);
            });
    });
};

const setPolicy = (dpName, policies, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-data-product/set-policy`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({ body: { dp_name: dpName, policies: policies, overwrite: true } }),
        })
            .then((res) => res.json())
            .then((json) => {console.log(json); onSuccess(json)})
            .catch(function (error) {
                console.log(error);
            });
    });
};

/**
 * 
 * @param {*} dpName 
 * @param {*} userName 
 * @param {*} accessToken 
 * @param {*} requestedRole 
 * @param {*} token 
 * @returns 
 */
const requestAccess = (dpName, userName, accessToken, requestedRole, token) => {
    return new Promise((onSuccess, onError) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/request-access`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: token,
            },
            body: JSON.stringify({
                body: {
                    productName: dpName,
                    userName: userName,
                    accessToken: accessToken,
                    requestedRole: requestedRole
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => onSuccess(json))
            .catch(function (error) {
                console.log(error);
            });
    });
};

// /git check



export default {
    getNotebookList,
    openNotebook,
    createNewNotebook,
    uploadFile,
    getDataHead,
    createDataProduct,
    getCatalogCrawlerStatus,
    accessData,
    saveDataProductSchema,
    getDataProductSchema,
    applyDataProductSchema,
    setPolicy,
    requestAccess   
};
