/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Card,
    Divider,
    Container,
    Button,
} from "@mui/material";
import { Box } from "@mui/system";
import Attribute from "./Attribute";

import DataProductService from "../services/DataProductService"

class ProvisionSourceForm extends React.Component {
    constructor() {
        super();
        this.state = {
            topicName: "data-mesh",
            s3URI: undefined,
            dataSources: [],
            dataProducts: [],
        };
    }

    getSourceConnectorConfig = () => {
        var s3Regex = /^s3:\/\/([^/]+)\/(.*?([^/]+)\/?)$/gm;
        return {
            s3SourceConnector: `"connector.class"="io.lenses.streamreactor.connect.aws.s3.source.S3SourceConnector"
"key.converter.schemas.enable"="false"
"connect.s3.kcql"="INSERT INTO ${this.state.topicName
                } SELECT * FROM ${this.state.s3URI.match(
                    s3Regex
                )}:test_source STOREAS \`JSON\`"
"aws.region"="eu-central-1"
"connect.s3.aws.client"="AWS"
"connect.s3.aws.region"="eu-central-1"
"tasks.max"="2"
"schema.enable"="false"
"value.converter"="org.apache.kafka.connect.storage.StringConverter"
"errors.log.enable"="true"
"key.converter"="org.apache.kafka.connect.storage.StringConverter"`,
        };
    };

    componentDidMount = () => {
        this.props.setProducerDetails(this.props.producer);
        this.getDataProducts()
    };

    handleChange = (input, type) => {
        if (type === "sourceSystem") {
            this.setState({ ...this.state, ...{ sourceSystem: input } });
            this.props.setSourceSystem(input);
        } else if (type === "s3URI") {
            this.setState({ ...this.state, ...{ s3URI: input } });
            this.props.setSourceDetails({ s3URI: input });
        } else if (type === "s3FileType") {
            this.props.setSourceDetails({ s3FileType: input });
        } else if (type === "selectedFile") {
            this.setState({ ...this.state, ...{ selectedFile: input } });
            console.log(this.state.selectedFile);
        } else if (type === "consumptionSystem") {
            this.setState({ ...this.state, ...{ consumptionSystem: input } });
            this.props.setSourceDetails({ consumptionSystem: input });
        } else if (type === "sourceConnector") {
            this.setState({ ...this.state, ...{ sourceConnector: input } });
        } else {
            this.setState({ ...this.state, ...{ [type]: input } });
        }
    };

    handleChangeFileType = (event) => {
        this.setState({
            ...this.state,
            ...{ fileType: event.target.value },
        });
    };

    addDataSource = (event) => {
        var dataSources = this.state.dataSources;
        // TODO: fix hard coded differentiation

        var name;

        if (this.state.sourceSystem == "s3") {
            name = this.props.s3URI;
        }
        else if (this.state.sourceSystem == "file") {

            name = this.state.selectedFile?.split("/").pop();

            DataProductService.uploadFile(this.updateDataSourceS3URI,
                name,
                document.getElementById('input').files,
                this.props.productName,
                this.props.producer.name,
                this.props.token);

            this.state.selectedFile = undefined;
        }
        else if (this.state.sourceSystem == "dataProduct") {
            name = this.state.dataProductName;
        }

        dataSources.push({
            sourceSystem: this.state.sourceSystem,
            name: name,
        });

        this.setState({
            ...this.state,
            ...{ dataSources: dataSources },
        });
        // TODO: We don't need to store data sources here and in parent state
        this.props.setDataSources(dataSources)
    };


    updateDataSourceS3URI = (name_old, s3URI) => {
        var dataSources = this.state.dataSources;
        // TODO: fix hard coded differentiation
        dataSources.find(e => e.name == name_old).name = s3URI;

        this.setState({
            ...this.state,
            ...{ dataSources: dataSources },
        });

        // TODO: We don't need to store data sources here and in parent state
        this.props.setDataSources(dataSources)
    };


    removeDataSource = (i) => {
        var dataSources = this.state.dataSources;
        // TODO: fix hard coded differentiation
        dataSources.splice(i, 1);

        this.setState({
            ...this.state,
            ...{ dataSources: dataSources },
        });

        // TODO: We don't need to store data sources here and in parent state
        this.props.setDataSources(dataSources)
    };

    // TODO: redundant with function DataProductView, place it a central place
    getDataProducts = () => {
        fetch(
            `https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/catalog/list-data-products`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    degheader: this.props.token,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(JSON.parse(json.body))
                this.setState({
                    ...this.state,
                    ...{ dataProducts: JSON.parse(json.body) },
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        return (
            <Card sx={{ padding: 5 }}>
                <Typography sx={{ fontStyle: "italic" }}>
                    In this section, you must select the data product's source system(s) and
                    provide information about its producer.
                </Typography>
                <br />
                <Typography sx={{ marginTop: 2 }}>
                    Information about producer.
                </Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Typography></Typography>
                <Box
                    sx={{
                        marginTop: 3,
                        marginBottom: 3,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ flex: 1 }}>Producer Name</Typography>
                        <TextField
                            disabled
                            style={{
                                flex: 2,
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                            size="small"
                            defaultValue={this.props.producer.name}
                            variant="outlined"
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ flex: 1 }}>Departement</Typography>
                        <TextField
                            disabled
                            style={{
                                flex: 2,
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                            size="small"
                            defaultValue={this.props.producer.department}
                            variant="outlined"
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ flex: 1 }}>Division</Typography>
                        <TextField
                            disabled
                            style={{
                                flex: 2,
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                            size="small"
                            defaultValue={this.props.producer.division}
                            variant="outlined"
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ flex: 1 }}>Email</Typography>
                        <TextField
                            disabled
                            style={{
                                flex: 2,
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                            size="small"
                            defaultValue={this.props.producer.email}
                            variant="outlined"
                        />
                    </div>
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography>Already selected sources.</Typography>
                    <Divider />

                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {this.state.dataSources.map((d, i) => (
                            <Card sx={{ padding: 2, margin: 2 }}>
                                <Typography>
                                    Source System: {d.sourceSystem}
                                </Typography>
                                <Typography>Name: {d.name}</Typography>
                                <Button
                                    sx={{ width: "100%" }}
                                    variant="contained"
                                    color="error"
                                    onClick={() => this.removeDataSource(i)}
                                >
                                    Remove
                                </Button>
                            </Card>
                        ))}
                    </Container>
                </Box>

                <Typography>
                    Information about the data source & the source connector.
                </Typography>

                <Divider sx={{ marginBottom: 4 }} />

                <FormControl sx={{ marginTop: 3 }} fullWidth>
                    <InputLabel>Source system</InputLabel>
                    <Select
                        label="Source system"
                        onChange={(evt) =>
                            this.handleChange(evt.target.value, "sourceSystem")
                        }
                    >
                        <MenuItem value={"s3"}>S3</MenuItem>
                        <MenuItem value={"dataProduct"}>Data Product</MenuItem>
                        <MenuItem value={"file"}>File</MenuItem>
                        <MenuItem value={"mysql"}>MySQL</MenuItem>
                        <MenuItem value={"mongodb"}>MongoDB</MenuItem>
                        <MenuItem value={"..."}>...</MenuItem>
                    </Select>
                </FormControl>

                {this.state.sourceSystem === "s3" && (
                    <Box
                        sx={{ marginTop: 2, display: "flex", flexFlow: "row", alignItems: "center", justifyContent: "space-between"}}
                    >

                        <Attribute
                            key={this.props.s3URI}
                            value={this.props.s3URI}
                            name={"S3 URI"}
                            hideName={true}
                            textProps={{
                                error: this.props.dpNameError !== undefined,
                                helperText: this.props.dpNameError,
                                onChange: (evt) => this.props.inputMetadataChange("s3URI", evt.target.value),
                                placeholder: "e.g. Sales for Product XYZ",
                                disabled: undefined,
                                size: undefined,
                                label: "S3 URI"
                            }}
                        />

                        <FormControl sx={{ flex: 0, flexBasis: "150px", marginLeft: "1rem" }} fullWidth>
                            <InputLabel>File Type</InputLabel>
                            <Select
                                value={"csv"}
                                label="File Type"
                                onChange={(evt) =>
                                    this.handleChange(
                                        evt.target.value,
                                        "s3FileType"
                                    )
                                }
                            >
                                <MenuItem value={"csv"}>CSV</MenuItem>
                                <MenuItem value={"json"}>JSON</MenuItem>
                                <MenuItem value={"xml"}>XML</MenuItem>
                                <MenuItem value={"parquet"}>Parquet</MenuItem>
                                <MenuItem value={"orc"}>ORC</MenuItem>
                                <MenuItem value={"avro"}>AVRO</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {this.state.sourceSystem === "dataProduct" && (
                    <Box
                        sx={{ marginTop: 2, display: "flex", flexFlow: "row" }}
                    >
                        <FormControl sx={{ flex: 1 }} fullWidth>
                            <InputLabel>Data Product</InputLabel>
                            <Select
                                value={this.state.dpProductName}
                                label="File Type"
                                onChange={(evt) =>
                                    this.handleChange(
                                        evt.target.value,
                                        "dpProductName"
                                    )
                                }
                            >
                                {this.state.dataProducts && this.state.dataProducts.map((d, i) => (
                                    <MenuItem value={d.Name}>{d.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}


                {this.state.sourceSystem === "file" && (
                    <Box
                        sx={{ marginTop: 2, display: "flex", flexFlow: "row" }}
                    >

                        <input
                            type="file"
                            multiple id="input"
                            style={{ display: 'none' }}
                            onChange={(evt) =>
                                this.handleChange(
                                    evt.target.value,
                                    "selectedFile"
                                )
                            }
                        />

                        <Button
                            onClick={() => document.getElementById('input').click()}
                            sx={{ flex: 1, marginLeft: 0 }}
                            variant="outlined">
                            Select File
                        </Button>

                        <Typography sx={{ flex: 2, marginRight: 0, marginLeft: 3, marginTop: 1 }}>
                            {this.state.selectedFile?.split("/").pop()}
                        </Typography>

                    </Box>
                )}

                <Button
                    sx={{ marginTop: 2, float: "right" }}
                    variant="contained"
                    onClick={this.addDataSource}
                >
                    Add
                </Button>
            </Card>
        );
    }
}

export default ProvisionSourceForm;
