import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Container,
    Typography,
    TextField,
    Card,
    Button,
    Divider,
} from "@mui/material";
import axios from "axios";

const { CognitoIdentityServiceProvider } = require("aws-sdk");

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            email: undefined,
            password: undefined,
        };
    
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.login(this.state.email, this.state.password)

    }

    render() {
        return (
            <Container
                sx={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                    <Card color="secondary.light" sx={{ padding: 5 }}>
                        <Typography h2>Data Mesh Showcase</Typography>
                        <Divider
                            orientation="horizontal"
                            sx={{ marginBottom: 2 }}
                        />
                        <Typography>
                            Please enter your login information.
                        </Typography>
                        <form style={{display: "flex", flexDirection: "column"}}
                            onSubmit={this.handleSubmit}>
                            <TextField
                                onChange={(e) =>
                                    this.setState({
                                        ...this.state,
                                        ...{ email: e.target.value },
                                    })
                                }
                                label="Email"
                                variant="outlined"
                                required
                                sx={{ margin: 2 }}
                            />
                            <TextField
                                onChange={(e) =>
                                    this.setState({
                                        ...this.state,
                                        ...{ password: e.target.value },
                                    })
                                }
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                variant="outlined"
                                required
                                sx={{ margin: 2 }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                // onClick={() => this.props.login(this.state.email, this.state.password)}
                                sx={{ margin: 2 }}
                            >
                                Login
                            </Button>
                            </form>

                        <Typography>Made with ✨ by CADP</Typography>
                    </Card>
            </Container>
        );
    }
}

export default Login;
