import {
    Button,
    Card,
    Divider,
    IconButton,
    Typography,
    Container,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Stack,
} from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DataProductAccessMenu from "./DataProductAccessMenu";
import RequestForm from "./RequestForm";
import DataProductService from "../services/DataProductService";
import AuthService from "../services/AuthService";
import { Loop } from "@mui/icons-material";


import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';


import {
    Menu as MenuIcon,
    AccountCircle,
    Dvr,
    ExpandMore,
    LockOpen,
    Input,
    ContactPage,
    FilterAlt,
} from "@mui/icons-material";

class DataProductCard extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: [],
            accessMenuOpen: false,
            anchorAccessMenu: undefined,
            dataHeadHeader: ["loading..."],
            dataHeadValues: [],
            openRequestDialog: false,
            requestedRole: undefined,
            tagList: [
                "tag1",
                "tag2",
                "inventory",
                "salesData",
                "supplyChainStuff",
                "someBuissnessData",
                "departmentData",
                "someMoreBuissnessData",
                "maybePrivateData",
                "revenueYOY",
                "forecastForSomething",
                "analysisOfXYZ",
            ],
            wholeStars:0,
            halfStars:0,
            emptyStars:0,
            rating:0,
        };
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.updateDataHead = this.updateDataHead.bind(this);
        this.checkPolicy = this.checkPolicy.bind(this);

        this.state.rating = Math.round((Math.random() < 0.5 ? ((1-Math.random()) * (5)) : (Math.random() * (5))) * 10) / 10;


        this.state.wholeStars = Math.floor(this.state.rating);

        if((this.state.rating % 1) > 0.5){
            this.state.halfStars = 1;
        } 

        this.state.emptyStars = 5 - this.state.wholeStars - this.state.halfStars;
    }

    componentDidMount() {
        this.checkPolicy();
        console.log(this.state.access)
    }

    checkPolicy() {
        console.log(this.props.dataProduct.Name)
        
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-data-quality`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
            body: JSON.stringify({
                body: {
                    dp_name:
                        this.props.dataProduct.Name === "etl_example_data"
                            ? "etl_example"
                            : this.props.dataProduct.Name,
                    token: this.props.access_token,
                    policy_type: "multiple_type",
                    policy_name: "access-policy",
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
                var accessRes = JSON.parse(json["body"]);
                var access = "no_access";

                // TODO: Optimize
                accessRes.forEach((e) => {
                    if (
                        e["details"].includes("reader") &&
                        e["passed"] &&
                        access !== "read-write" &&
                        access !== "developer" &&
                        access !== "owner"
                    ) {
                        access = "read";
                    } else if (
                        e["details"].includes("writer") &&
                        e["passed"] &&
                        access !== "developer" &&
                        access !== "owner"
                    ) {
                        access = "read-write";
                    } else if (
                        e["details"].includes("developer") &&
                        e["passed"] &&
                        access !== "owner"
                    ) {
                        access = "developer";
                    } else if (e["details"].includes("owner") && e["passed"]) {
                        access = "owner";
                    }
                });
                // console.log(this.props.dataProduct.Name, access)
                
                this.setState({ access: access }, () => console.log(this.state.access));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleClick = (event, type = "accessMenu") => {
        if (type === "accessMenu") {
            this.setState({
                accessMenuOpen: true,
                anchorAccessMenu: event.currentTarget,
            });
        } else if (type == "permissionMenu") {
            this.setState({
                permissionMenuOpen: true,
                anchorPermissionMenu: event.currentTarget,
            });
        }
    };

    handleClose = (type = "accessMenu") => {
        if (type === "accessMenu") {
            this.setState({ accessMenuOpen: false, anchorAccessMenu: null });
        } else if (type == "permissionMenu") {
            this.setState({
                permissionMenuOpen: false,
                anchorPermissionMenu: null,
            });
        }
    };

    handleChange = (input, key) => {
        this.setState({ ...this.state, ...{ [key]: input } });
    };

    handleExpandClick = (i) => {
        var _expanded = this.state.expanded;
        _expanded[i] = !this.state.expanded[i];
        this.setState({ ...this.state, ...{ expanded: _expanded } });
    };

    updateDataHead = (dataheader, datavalues) => {
        this.state.dataHeadHeader = dataheader;
        this.state.dataHeadValues = datavalues;

        var _expanded = this.state.expanded;
        _expanded[this.props.idy] = this.state.expanded[this.props.idy];
        this.setState({ ...this.state, ...{ expanded: _expanded } });
    };

    render() {
        return (
            <Card
                color="secondary.light"
                sx={{
                    margin: "5px",
                    width: "100%",
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItem: "baseline",
                    marginBottom: 5,
                    authPolicyResult: [],
                }}
            >
                
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >

                   

                    <Typography variant="h5">Name: {this.props.dataProduct.Name}</Typography>
                    <Typography variant="h5" sx={{ marginRight: 10 }}>
                        Owner:{" "}
                        {this.props.dataProduct.Parameters.owner
                            ? this.props.dataProduct.Parameters.owner
                            : this.props.dataProduct.Owner}
                    </Typography>
                    <IconButton onClick={this.checkPolicy}>
                        <Loop />
                    </IconButton>
                </Container>
                <Divider
                    style={{
                        width: "100%",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                />


                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "wrap",
                    }}
                >
                    {/* User Rating */}
                    <Stack direction="row" sx={{marginRight:10}}>
                        <Typography sx={{ marginRight: 2 }}>User rating: </Typography>

                            {Array(this.state.wholeStars).fill(<StarIcon sx={{color:"#ffda6a"}}/>)}
                            {Array(this.state.halfStars).fill(<StarHalfIcon sx={{color:"#ffda6a"}}/>)}
                            {Array(this.state.emptyStars).fill(<StarBorderIcon sx={{color:"#ffda6a"}}/>)}
                    
                    </Stack>

                    <Typography sx={{ marginRight: 10 }}>Creation Time: {this.props.dataProduct.CreateTime}</Typography>
                    <Typography sx={{ marginRight: 10 }}>Database: {this.props.dataProduct.DatabaseName}</Typography>
                    <Typography sx={{ marginRight: 10 }}>
                        Last Access Time: {this.props.dataProduct.LastAccessTime}
                    </Typography>
                    <Typography sx={{ marginRight: 10 }}>Tabel Type: {this.props.dataProduct.TableType}</Typography>
                    <Typography sx={{ marginRight: 10 }}>
                        Location:{" "}
                        {this.props.dataProduct.StorageDescriptor
                            ? this.props.dataProduct.StorageDescriptor.Location
                            : ""}
                    </Typography>
                </Container>

                <Divider
                    style={{
                        width: "100%",
                        marginTop: 8,
                        marginBottom: 2,
                    }}
                />

                <Box>
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "left",
                            justifyContent: "left",
                            flexWrap: "wrap",
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        {this.state.tagList &&
                            this.state.tagList.map((tag, i) => (
                                <Box
                                    bgcolor="#353535"
                                    style={{
                                        marginLeft: 10,
                                        marginBottom: 4,
                                        marginTop: 4,
                                        borderRadius: "5px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        color="grey"
                                        style={{
                                            marginLeft: 3,
                                            marginRight: 3,
                                        }}
                                    >
                                        {tag}
                                    </Typography>
                                </Box>
                            ))}
                    </Container>
                </Box>

                <Divider
                    style={{
                        width: "100%",
                        marginTop: 2,
                        marginBottom: 4,
                    }}
                />

                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "row",
                        marginTop: 2,
                    }}
                >
                    <Typography>Schema</Typography>
                    <IconButton
                        aria-label="user"
                        sx={{ order: 2, marginLeft: "auto" }}
                        onClick={() => this.handleExpandClick(this.props.idx)}
                    >
                        <ExpandMore />
                    </IconButton>
                </Container>
                <Divider
                    style={{
                        width: "100%",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                />
                <Container>
                    <Collapse in={this.state.expanded[this.props.idx]} timeout="auto" unmountOnExit>
                        {this.props.dataProduct.StorageDescriptor &&
                            this.props.dataProduct.StorageDescriptor.Columns.map((y) => (
                                <Container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>{y.Name}</Typography>
                                    <Typography>{y.Type}</Typography>
                                </Container>
                            ))}
                    </Collapse>
                </Container>

                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "row",
                        marginTop: 2,
                    }}
                >
                    <Typography>Preview Data</Typography>
                    <IconButton
                        aria-label="user"
                        sx={{ order: 2, marginLeft: "auto" }}
                        onClick={() => {
                            this.handleExpandClick(this.props.idy);
                            DataProductService.getDataHead(
                                this.props.dataProduct,
                                this.props.token,
                                this.updateDataHead
                            );
                        }}
                    >
                        <ExpandMore />
                    </IconButton>
                </Container>

                <Divider
                    style={{
                        width: "100%",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                />

                <Container>
                    <Collapse in={this.state.expanded[this.props.idy]} timeout="auto" unmountOnExit>
                        {["read", "read-write", "developer", "owner"].includes(this.state.access) && (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 400 }} aria-lable="preview-table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            {this.state.dataHeadHeader.map((head, headID) => (
                                                <TableCell>
                                                    <Typography>
                                                        <Box sx={{ fontWeight: "bold" }}>{head}</Box>
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.dataHeadValues.map((values, index) => (
                                            <TableRow>
                                                {values.map((value, index) => (
                                                    <TableCell>{value}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {!["read", "read-write", "developer", "owner"].includes(this.state.access) && (
                            <Typography>No Access</Typography>
                        )}
                    </Collapse>
                </Container>

                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "wrap",
                        padding: 2,
                        justifyContent: "space-around",
                    }}
                >
                    {!["read", "read-write", "developer", "owner"].includes(this.state.access) && (
                        <Button
                            variant="contained"
                            startIcon={<LockOpen />}
                            onClick={() => this.setState({ openRequestDialog: true })}
                        >
                            Request Access
                        </Button>
                    )}
                    <Button
                        disabled={!["read", "read-write", "developer", "owner"].includes(this.state.access)}
                        variant="contained"
                        startIcon={<Input />}
                        onClick={(event) => this.handleClick(event, "accessMenu")}
                    >
                        Access Data
                    </Button>
                    <DataProductAccessMenu
                        handleClose={this.handleClose}
                        token={this.props.token}
                        user={this.props.user}
                        accessMenuOpen={this.state.accessMenuOpen}
                        anchorAccessMenu={this.state.anchorAccessMenu}
                        dataProduct={this.props.dataProduct}
                    />
                    <Button variant="contained" startIcon={<ContactPage />}>
                        Contact Owner
                    </Button>

                    <Button variant="contained" startIcon={<ContactPage />}>
                        Documentation
                    </Button>
                </Container>

                <Dialog
                    open={this.state.openRequestDialog}
                    onClose={() => this.setState({ openRequestDialog: false })}
                    fullWidth={true}
                    maxWidth={"lg"}
                    sx={{
                        ".MuiPaper-root": {
                            padding: 2,
                        },
                    }}
                >
                    <RequestForm
                        token={this.props.token}
                        accessToken={this.props.access_token}
                        user={this.props.user}
                        productName={this.props.dataProduct.Name}
                        reload={this.checkPolicy}
                    />
                </Dialog>
            </Card>
        );
    }
}

export default DataProductCard;
