/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React from "react";
import {
    Button,
    ButtonBase,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import Attribute from "./Attribute"

class ProvisionCheckUpForm extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount = () => { console.log(this.props  ) };

    render() {
        return (
            <Card sx={{ padding: 5 }}>
                <Typography sx={{ marginBottom: 3 }}>Kindly take a moment to review the information you've provided
                    thoroughly to ensure that the data product meets high quality standards.
                </Typography>
                {/* <pre>{JSON.stringify(this.props.provisionDetails, null, 4)}</pre> */}

                <Box>
                    <Typography variant="h6">Business Metadata</Typography>
                    <Divider sx={{ marginBottom: 4 }} />

                    <Attribute
                        name={"Producer:"}
                        value={this.props.provisionDetails.dpProducer.name} />
                    <Attribute
                        name={"Producer Department:"}
                        value={this.props.provisionDetails.dpProducer.department} />
                    <Attribute 
                        name={"Producer Division:"}
                        value={this.props.provisionDetails.dpProducer.division} />
                    <Attribute 
                        name={"Producer Email:"}
                        value={this.props.provisionDetails.dpProducer.email} />
                    <Attribute 
                        name={"Product Name:"} 
                        value={this.props.provisionDetails.dpProductName} />
                    <Attribute 
                        name={"Security Standard:"}
                        value={this.props.provisionDetails.dpSecurityStandard} />
                    <Attribute
                        name={"Custom Policy:"}
                        value={JSON.stringify(this.props.provisionDetails.dpPolicies, null, 4)}
                        textProps={{ multiline: true }}
                    />
                    <Attribute 
                        name={"Source System:"} 
                        value={this.props.provisionDetails.dpSourceSystem} />
                    <Attribute 
                        name={"Source Details:"} 
                        value={JSON.stringify(this.props.provisionDetails.dpSourceDetails, null, 4)} />
                    <Attribute 
                        name={"Source System:"} 
                        value={this.props.provisionDetails.dpSourceSystem} />

                    <Typography variant="h6">Target Details</Typography>
                    <Divider sx={{ marginBottom: 4 }} />
                    <Attribute 
                        name={"Target Details:"} 
                        value={JSON.stringify(this.props.provisionDetails.dpSinkDetails, null, 4)} />
                </Box>
            </Card>
        );
    }
}

export default ProvisionCheckUpForm;
