import {
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";

import { styled } from "@mui/system";
import ContactMailIcon from '@mui/icons-material/ContactMail';

const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "solid 1px",
    borderRadius: 3,
    borderColor: "#78909c",
}));


class DataProductDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            accessMenuOpen: false,
            anchorAccessMenu: null,
        };
    }


    render() {
        return (
            <div style={{ ...this.props.style }}>
                <Divider orientation="horizontal" textAlign="left">
                    <b>Permissions</b>
                </Divider>
                <Typography>Data Access: Read/Write</Typography>
                <Typography>Meta Data Access: Read</Typography>
                <Button variant="contained" startIcon={<ContactMailIcon/>}>
                                            Connect with producer
                                        </Button>


                <Divider orientation="horizontal" textAlign="left" style={{marginTop: 20}}>
                    <b>Product Metadata</b>
                </Divider>
                {Object.entries(this.props.product.Parameters).map((x) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{flex: 1}}>{x[0]}:</Typography>
                        <TextField
                            disabled
                            style={{ flex: 2, marginLeft: 10, marginBottom: 10 }}
                            size="small"
                            defaultValue={x[1]}
                            variant="outlined"
                        />
                    </div>
                ))}
            </div>
        );
    }
}

export default DataProductDetails;
