import {
    Container,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Typography,
    Divider,
    Button,
    Box,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import React from "react";
import { Delete, Loop, Add } from "@mui/icons-material";
import DataProductService from "../services/DataProductService";
import ReactLoading from "react-loading";

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import exampleData from "../assets/example_data.json"

const PolicyRow = (props) => {
    const [state, setState] = React.useState({
        Name: undefined,
        Type: undefined,
        Parameters: {
            constraints: undefined,
        },
        Comment: undefined,
    });

    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 3,
                marginBottom: 3,
                flexWrap: "wrap",
                //     borderWidth: "1px",
                //     borderStyle: "solid",
                //     borderColor: "rgba(255, 255, 255, 0.23)",
                //     borderRadius: "4px",
            }}
        >
            <TextField
                size="small"
                sx={{ minWidth: 250, margin: 1 }}
                onChange={(evt) => props.changeDetails({ policyName: evt.target.value })}
                variant="outlined"
                value={props.details ? props.details.policyName : ""}
                placeholder={props.details?.policyName || "Policy Name*"}
            />

            <FormControl size="small" sx={{ margin: 1, minWidth: 150 }}>
                <InputLabel>Policy Type</InputLabel>
                <Select
                    sx={{ flexGrow: 1 }}
                    label="Data Type"
                    value={
                        props.details && props.details.policyType !== "" ? props.details.policyType : "access-policy"
                    }
                    // onChange={(evt) => {setState({Type: evt.target.value})}}
                    onChange={(evt) => props.changeDetails({ policyType: evt.target.value })}
                >
                    <MenuItem value={"access-policy"}>Access Policy</MenuItem>
                    <MenuItem value={"data-quality"}>Data Quality</MenuItem>
                    <MenuItem value={"..."}>...</MenuItem>
                </Select>
            </FormControl>

            <TextField
                size="small"
                sx={{ minWidth: 250, margin: 1 }}
                onChange={(evt) => props.changeDetails({ passRequirement: evt.target.value })}
                variant="outlined"
                value={props.details ? props.details.passRequirement : ""}
                placeholder={props.details?.passRequirement || "Pass Requirement*"}
            />

            <FormGroup sx={{ marginLeft: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            onChange={(evt) =>
                                props.changeDetails({
                                    fixed: evt.target.checked ? "fixed" : "soft",
                                })
                            }
                            checked={
                                props.details?.fixed === undefined ||
                                (props.details?.fixed !== "" && props.details?.fixed == "fixed")
                            }
                        />
                    }
                    label="Fixed?"
                />
            </FormGroup>

            <TextField
                size="small"
                sx={{ minWidth: 250, margin: 1 }}
                onChange={(evt) => props.changeDetails({ failureMessage: evt.target.value })}
                variant="outlined"
                value={props.details ? props.details.failureMessage : ""}
                placeholder={props.details?.failureMessage || "Failure Message*"}
            />

            <TextField
                size="small"
                sx={{ minWidth: 250, margin: 1 }}
                onChange={(evt) => props.changeDetails({ successMessage: evt.target.value })}
                variant="outlined"
                value={props.details ? props.details.successMessage : ""}
                placeholder={props.details?.successMessage || "Success Message*"}
            />

            <FormControl sx={{ margin: 1, minWidth: 150 }} size="small">
                <InputLabel>Code Type</InputLabel>
                <Select
                    label="Data Type"
                    value={props.details && props.details.codeType !== "" ? props.details.codeType : "logic"}
                    // onChange={(evt) => {setState({Type: evt.target.value})}}
                    onChange={(evt) => props.changeDetails({ codeType: evt.target.value })}
                >
                    <MenuItem value={"logic"}>Logic</MenuItem>
                    <MenuItem value={"sql"}>SQL</MenuItem>
                    <MenuItem value={"py_func"}>Python Function</MenuItem>
                    <MenuItem value={"lambda"}>.Lambda Function</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                multiline
                size="small"
                sx={{ minWidth: 250, margin: 1 }}
                onChange={(evt) => props.changeDetails({ policyCode: evt.target.value })}
                variant="outlined"
                value={props.details ? props.details.policyCode : ""}
                placeholder={props.details?.policyCode || "Policy Code*"}
            />

            {props.enableDeletion && (
                // <IconButton
                //     aria-label="user"
                //     sx={{ order: 2, marginLeft: 3 }}
                //     onClick={(evt) => props.changeDetails(undefined)}
                // >
                //     <Delete />
                // </IconButton>
                <Button
                    variant="contained"
                    sx={{ order: 2, marginLeft: 3 }}
                    onClick={(evt) => props.changeDetails(undefined)}
                >
                    Delete Rule
                </Button>
            )}
        </Container>
    );
};

class PolicyEditor extends React.Component {
    constructor() {
        super();
        this.state = {
            mode: "ui",
            policyJsonError: false,
            newPolicyObject: {},
            policy: [],
        };

        this.setExampleValue = this.setExampleValue.bind(this)
    }

    componentDidMount = () => {};

    loadSchema = () => {};

    savePolicy = () => {
        DataProductService.setPolicy(this.props.dpName, this.props.policies, this.props.token)
    };

    addNewPolicyRule = () => {
        console.log(this.state.newPolicyObject);
        this.props.addNewPolicyRule(this.state.newPolicyObject);
    };

    /**
     * Is used for the policy row
     * @param {*} i
     * @param {*} details
     */
    changePolicyDetails = (i, details) => {
        var tmpPolicy = this.props.policies;

        if (details == undefined) {
            tmpPolicy.splice(i, 1);
        } else {
            tmpPolicy[i] = { ...tmpPolicy[i], ...details };
        }

        this.props.setPolicy(tmpPolicy);
    };

    changePolicy = (value) => {
        try {
            var policy = JSON.parse(value);

            console.log(policy)

            this.setState({ policy: policy, policyJsonError: false });
            this.props.setPolicy(policy);
        } catch (e) {
            console.log("Not a valid JSON");
            this.setState({ policyJsonError: true });
        }
    };

    setExampleValue() {
        const examplePolicy = exampleData["Policy"].replaceAll("'", "\"")

        this.changePolicy(examplePolicy)

        // console.log(JSON.parse(examplePolicy))

        // this.setState((prev) => ((prev.policy = JSON.parse(examplePolicy)), prev))
    }

    render() {
        return (
            <Container sx={{ padding: 0, marginTop: 1, marginBottom: 2 }}>
                {this.state.loading && (
                    <Container
                        sx={{
                            backgroundColor: "grey",
                            opacity: 0.5,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            zIndex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ReactLoading type={"cylon"} />
                    </Container>
                )}

                <Container
                    sx={{
                        padding: "0px !important",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 2,
                    }}
                >
                    <Typography>Please create or adapt the local policy for this data product.</Typography>
                    <Button variant="contained">Reload</Button>
                    <Button variant="contained" onClick={() => this.savePolicy()}>
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() =>
                            this.setState({
                                mode: this.state.mode === "json" ? "ui" : "json",
                            })
                        }
                    >
                        Switch to {this.state.mode === "json" ? "ui" : "json"}
                    </Button>
                    
                    <IconButton 
                        sx={{ marginLeft: "1rem", alignSelf: "center" }} 
                        onClick={this.setExampleValue} >
                        <AutoFixHighIcon />
                    </IconButton>


                </Container>

                <Container
                    sx={{
                        // borderWidth: "1px",
                        // borderStyle: "solid",
                        // borderColor: "rgba(255, 255, 255, 0.23)",
                        // borderRadius: "4px",
                        padding: "0 !important",
                        overflow: "auto",
                    }}
                >
                    {this.state.mode === "json" && (
                        <TextField
                            error={this.state.policyJsonError}
                            helperText={this.state.policyJsonError ? "Not a valid JSON." : ""}
                            onChange={(evt) => this.changePolicy(evt.target.value)}
                            sx={{ marginTop: 4, width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                            id="outlined-multiline-static"
                            label="Policy Code"
                            multiline
                            minRows={4}
                            defaultValue={JSON.stringify(this.props.policies, null, 4)}
                        />
                    )}
                    <Divider flexItem />
                    {this.state.mode === "ui" && (
                        <Container sx={{ padding: "0 !important" }}>
                            <Container
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minHeight: "50px",
                                    overflowX: "auto",
                                }}
                            >
                                {this.props.policies?.length === 0 && (
                                    <Typography sx={{ marginTop: 2 }}>Please add a policy.</Typography>
                                )}

                                {this.props.policies?.map((p, i) => (
                                    <div>
                                        <PolicyRow
                                            details={{
                                                policyName: p.policyName,
                                                policyType: p.policyType,
                                                passRequirement: p.passRequirement,
                                                fixed: p.fixed,
                                                failureMessage: p.failureMessage,
                                                successMessage: p.successMessage,
                                                codeType: p.codeType,
                                                policyCode: p.policyCode,
                                            }}
                                            changeDetails={(newDetails) => this.changePolicyDetails(i, newDetails)}
                                            enableDeletion={true}
                                        />

                                        {i !== this.props.policies.length - 1 && <Divider />}
                                    </div>
                                ))}
                            </Container>

                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                            <Typography>Add new policy rule.</Typography>
                            <PolicyRow
                                details={this.state.newPolicyObject}
                                changeDetails={(newDetails) =>
                                    this.setState({
                                        newPolicyObject: {
                                            ...this.state.newPolicyObject,
                                            ...newDetails,
                                        },
                                    })
                                }
                            />
                            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Button variant="contained" onClick={() => this.addNewPolicyRule()}>
                                    Add policy rule
                                </Button>
                            </Container>
                        </Container>
                    )}
                </Container>
            </Container>
        );
    }
}

export default PolicyEditor;
