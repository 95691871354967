import config from "../config";
const axios = require('axios').default;

var users = {
    "producer": {
        "ownedDataProducts": [
            "mmsr_csv"
        ],
        "permissions": {
            "mesh_test_topic": ["readMetadata", "fullDataAccess"],
            "mmsr_csv": ["readMetadata", "fullDataAccess"],
            "mmsr_10_2022": ["readMetadata", "fullDataAccess"]
        }
    },
    "consumer-a": {
        "ownedDataProducts": [],
        "permissions": {
            "mesh-test-topic": ["readMetadata", "fullDataAccess"]
        }
    },
    "consumer-b": {
        "ownedDataProducts": [],
        "permissions": {
            "mmsr_csv": ["readMetadata", "fullDataAccess"]
        }
    }
}


/**
 * This function send the post request to the server to create a data product.
 * @param {object} data 
 */
const getUserData = () => {
    return new Promise((onSuccess, onError) => {
        axios.get(`http://${config.server.ip}:${config.server.port}/api/getUserData`)
            .then(function (response) {
                onSuccess(response);
            })
            .catch(function (error) {
                onError(error);
            });
    })
};

const requestPermission = (user, target, permission) => {
    return new Promise((onSuccess, onError) => {
        axios.post(`http://${config.server.ip}:${config.server.port}/api/requestPermission`,
            {
                user: user,
                target: target,
                permission: permission
            }
        )
            .then(function (response) {
                onSuccess(response.data);
            })
            .catch(function (error) {
                console.log("error")
                onError(error);
            });
    })
};

const getPermissionRequests = (user) => {
    return new Promise((onSuccess, onError) => {
        axios.post(`http://${config.server.ip}:${config.server.port}/api/getPermissionRequests`,
            {
                user: user,
            }
        )
            .then(function (response) {
                onSuccess(response);
            })
            .catch(function (error) {
                console.log("error")
                onError(error);
            });
    })
};

const approvePermissionRequest = (user, requstDetails) => {
    return new Promise((onSuccess, onError) => {
        axios.post(`http://${config.server.ip}:${config.server.port}/api/approvePermissionRequest`,
            {
                user: user,
                requestUser: requstDetails[0],
                target: requstDetails[1],
                permission: requstDetails[2]
            }
        )
            .then(function (response) {
                onSuccess(response);
            })
            .catch(function (error) {
                console.log("error")
                onError(error);
            });
    })
};


export {
    getUserData,
    requestPermission,
    getPermissionRequests,
    approvePermissionRequest
};