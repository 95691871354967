import { 
    Button, 
    Link, 
    Menu, 
    MenuItem, 
    Typography, 
    Container, 
    Box, 
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
 } from "@mui/material";
import React from "react";
import config from "../config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DataProductService from "../services/DataProductService"

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/cjs/styles/hljs";


const dataAccessConfig = {
    mesh_test_topic: ["downloadFile", "accessPrestoJupyter"],
};

const WorkgroupList = [{value: 'DataMeshWorkgroupSpark', label: 'DataMeshWorkgroupSpark'},
                        {value: 'DataMeshWorkgroupBene', label: 'DataMeshWorkgroupBene'},
                        {value: 'DataMeshWorkgroupSQL', label: 'DataMeshWorkgroupSQL'}]

const heading = ['Name','ID','Creation-Date','']

class DataProdcutAccessMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            accessDialogOpen: false,
            dialogTextName: "default",
            dialogText:{
                default: {
                    title: "Here we describe how one can access the data using different systems",
                    descr: "Some description",
                }
            },
            NotebookDialogOpen: false,
            createNotebookDialogOpen: false,
            notebookname_input: "",
            workgroupInput: "DataMeshWorkgroupSpark",
            workgroupSelection: "DataMeshWorkgroupSpark",
            usersInput: "",
            NotebookList: [],

        };
    };

    componentDidMount = () => {
        var dialogText = {
            Athena: {
                title: "Access the data using Athena",
                descr: `To access the data using your own Presto instance and a Jupyter
                        notebook you have to make sure that Presto ist correctly configured 
                        and that the SQL magic function is installed for the Jupyter notebook.`,
                codeSnippets: [
                    `SELECT * FROM "mesh_db"."${this.props.dataProduct.Name}" limit 10;`,
                ],
                url: "https://eu-central-1.console.aws.amazon.com/athena/home?region=eu-central-1#/query-editor"
            },
            default: {
                title: "Here we describe how one can access the data using different systems",
                descr: "Some description",
            },
        };
        this.setState({...this.state, ...{dialogText: dialogText}})
    };



    showAccessDialogOpen = (dialogTextName = "default") => {
        this.setState({ ...this.state, ...{ accessDialogOpen: !this.state.accessDialogOpen, dialogTextName: dialogTextName} });
    };

    showCreateNotebookDialogOpen = () => {
        this.setState({ ...this.state, ...{ createNotebookDialogOpen: !this.state.createNotebookDialogOpen} });
    };    

    showNotebookDialogOpen = () => {
        if(!this.state.NotebookDialogOpen){
            DataProductService.getNotebookList(this.refreshNotebookDialog,this.props.user,this.props.dataProduct,this.state.workgroupSelection,this.props.token);
        }
        this.setState({ ...this.state, ...{ NotebookDialogOpen: !this.state.NotebookDialogOpen} });
    };
    
    refreshNotebookDialog = (list) => {
        if(list != undefined){
            this.state.NotebookList = list;
        }else{
            DataProductService.getNotebookList(this.refreshNotebookDialog,this.props.user,this.props.dataProduct,this.state.workgroupSelection,this.props.token);
        }
        this.setState({ ...this.state, ...{ NotebookDialogOpen: true} });
    };

    inputMetadataChange = (type, value) => {
        this.setState({
            ...this.state,
            ...{ [type]: value },
        });
    }; 

   

    render() {
        return (
            <div>
                <Menu
                    anchorEl={this.props.anchorAccessMenu}
                    open={this.props.accessMenuOpen}
                    onClose={() => this.props.handleClose("accessMenu")}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {/* TODO: Make it dynamic based on DP configuration */}
                            <MenuItem onClick={() => DataProductService.accessData(this.props.dataProduct, "file_download", this.props.token)}>
                                Download File
                            </MenuItem>
                            <MenuItem onClick={() => this.showAccessDialogOpen("Athena")}>
                                Athena
                            </MenuItem>
                            <MenuItem onClick={() => this.showNotebookDialogOpen()}>
                                Jupyter Notebook
                            </MenuItem>
                            <MenuItem>
                                E.g. REST API
                            </MenuItem>                    
                </Menu>

                <Dialog
                    open={this.state.accessDialogOpen}
                    onClose={() => this.showAccessDialogOpen()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>{this.state.dialogText[this.state.dialogTextName].title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>{this.state.dialogText[this.state.dialogTextName].descr}</Typography>
                        </DialogContentText>

                        {this.state.dialogText[this.state.dialogTextName].codeSnippets &&
                            this.state.dialogText[this.state.dialogTextName].codeSnippets.map((x) => (
                                <div>
                                <SyntaxHighlighter showLineNumbers={true} wrapLines={true}  language="python" style={docco}>
                                    {x}
                                </SyntaxHighlighter>
                                <Container sx={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                                    <Button onClick={() => (navigator.clipboard.writeText(x))}>Copy to Clipboard</Button>
                                    <Button variant="contained" onClick={() => window.open(this.state.dialogText[this.state.dialogTextName].url, "_blank")}>Open {this.state.dialogTextName}</Button>
                                </Container>
                                </div>
                            ))}

                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.NotebookDialogOpen}                    
                    onClose={() => this.showNotebookDialogOpen()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"                   
                >
                    <DialogTitle>                        
                        Notebook Selection

                        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                            <Typography sx={{flex: 1}}>Workgroup</Typography>
                            <TextField
                                onChange={(evt) => { 
                                this.inputMetadataChange("workgroupSelection", evt.target.value); 
                                DataProductService.getNotebookList(this.refreshNotebookDialog,this.props.user,this.props.dataProduct, evt.target.value ,this.props.token);
                            }}
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                    marginBottom: 10,
                                }}
                                select
                                defaultValue="DataMeshWorkgroupSpark"
                                size="small"
                                variant="outlined"
                            >
                                 {WorkgroupList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography sx={{textDecoration: 'underline'}}></Typography>
                        </DialogContentText>

                        <TableContainer sx={{marginBottom: 1}}>
                            <Table sx={{minWidth: 500, marginBottom: 2}} aria-lable="notebook-table" size="small">
                                <TableHead>
                                    <TableRow>
                                        {heading.map(
                                            (head, headID) => 
                                                <TableCell>
                                                    <Typography>
                                                        <Box sx={{ fontWeight: 'bold',textDecoration: 'underline' }}>{head}</Box>
                                                    </Typography>
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.NotebookList.map(
                                        (notebook, index) => 
                                        <TableRow>
                                            <TableCell>
                                                {notebook.name}
                                            </TableCell>

                                            <TableCell>
                                                <Typography sx={{fontSize: 10}}>{notebook.id}</Typography>
                                            </TableCell>

                                            <TableCell>
                                                {notebook.creation_date.substring(0,10) + " " + notebook.creation_date.substring(11,16)}
                                            </TableCell>

                                            <TableCell>
                                                <Button onClick={() => DataProductService.openNotebook(notebook,this.props.dataProduct,this.props.user,this.state.workgroupSelection, this.props.token)}>Open</Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button onClick={() => this.showCreateNotebookDialogOpen("Notebook")}>Create New Notebook</Button>
                    </DialogContent>
                </Dialog>

                
                <Dialog
                open={this.state.createNotebookDialogOpen}                    
                onClose={() => this.showCreateNotebookDialogOpen()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>                        
                        Create a new Notebook
                    </DialogTitle>
                    <DialogContent>
                        
                        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                            <Typography sx={{flex: 1}}>Notebook-Name</Typography>
                            <TextField
                                onChange={(evt) => this.inputMetadataChange("notebookname_input", evt.target.value)}
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                    marginBottom: 10,
                                }}
                                size="small"
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                            <Typography sx={{flex: 1}}>Other Users</Typography>
                            <TextField
                                onChange={(evt) => this.inputMetadataChange("usersInput", evt.target.value)}
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                    marginBottom: 10,
                                }}
                                size="small"
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                            <Typography sx={{flex: 1}}>Workgroup</Typography>
                            <TextField
                                onChange={(evt) => this.inputMetadataChange("workgroupInput", evt.target.value)}
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                    marginBottom: 10,
                                }}
                                select
                                defaultValue="DataMeshWorkgroupSpark"
                                size="small"
                                variant="outlined"
                            >
                                 {WorkgroupList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        
                        <Button onClick={() => { DataProductService.createNewNotebook(this.refreshNotebookDialog,this.props.user,this.state.usersInput,this.props.dataProduct, this.state.notebookname_input, this.state.workgroupInput, this.props.token);this.showCreateNotebookDialogOpen(); }}>Create New Notebook</Button>
                    </DialogContent>
                </Dialog>
                

            </div>
        );
    }
}

export default DataProdcutAccessMenu;