import { CognitoJwtVerifier } from "aws-jwt-verify";
import * as AWS from "aws-sdk"; //{ CognitoIdentityServiceProvider } from "aws-sdk";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider;

const awsConfig = {
    region: "eu-central-1",
    userPool: {
        UserPoolId: "eu-central-1_qzwGXVl7T",
        ClientId: "1pvcid2ci2qmik088t3qfg8b3h",
    },
    identityPool: {
        IdentityPoolId: "eu-central-1:a9a90dc9-6e86-47ed-a5ea-6cb2e496c2ae",
    },
};

const verifier = CognitoJwtVerifier.create({
    userPoolId: awsConfig.userPool.UserPoolId,
    tokenUse: "id", // id for id token, access for access token
    clientId: awsConfig.userPool.ClientId,
});

const checkToken = (token) => {
    return new Promise(async (onSuccess, onError) => {
        try {
            const payload = await verifier.verify(token);
            onSuccess();
        } catch (error) {
            onError();
        }
    });
};

const getAwsCredentials = (token) => {
    var cognitoUserPoolLoginProvider =
        "cognito-idp." +
        awsConfig.region +
        ".amazonaws.com/" +
        awsConfig.userPool.UserPoolId;
    var logins = {};
    logins[cognitoUserPoolLoginProvider] = token;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsConfig.identityPool.IdentityPoolId,
        Logins: logins,
    });

    AWS.config.update({ region: awsConfig.region });

    return new Promise((onSuccess, onError) => {

        AWS.config.credentials.get(function (err) {
            if (err) {
                // callback(err, null);
                console.log(err);
                console.log(AWS.config.credentials);
            } else {
                var creds = {
                    AccessKeyId: AWS.config.credentials.accessKeyId,
                    SecretAccessKey: AWS.config.credentials.secretAccessKey,
                    SessionToken: AWS.config.credentials.sessionToken,
                };
                onSuccess(creds);
            }
        });
    });
};

const login = (username, password) => {
    var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
    var AuthenticationDetails = AmazonCognitoIdentity.AuthenticationDetails;
    var CognitoUser = AmazonCognitoIdentity.CognitoUser;

    var userPool = new CognitoUserPool(awsConfig.userPool);

    AWS.config.region = awsConfig.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
        awsConfig.identityPool
    );

    var authenticationData = {
        Username: username,
        Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var userData = {
        Username: username,
        Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);

    return new Promise((onSuccessParent, onError) => {
        try {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    var id_token = result.getIdToken().getJwtToken();
                    var access_token = result.getAccessToken().getJwtToken();
                    onSuccessParent({token: id_token, access_token: access_token});
                },
                onFailure: function (err) {
                    // callback(null, result);
                    onError(err);
                },
                newPasswordRequired: function() {
                    // dont change pw, just keep existing pw
                    cognitoUser.completeNewPasswordChallenge(password, {}, this);
                },
            });
        } catch (error) {
            onError(error);
        }
    });
};

export default {
    checkToken,
    login,
    getAwsCredentials
};
