/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React from "react";
import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Checkbox,
    Container,
    FormControlLabel
} from "@mui/material";
import { Box } from "@mui/system";

class ProvisionSinkForm extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount = () => {
        this.props.setSinkDetails({
            targetSystem: "presto",
            table: "mesh_table",
            database: "mesh_db",
        });
    };

    handleChange = (input, type) => {
        if (type === "targetSystem") {
            this.setState({ ...this.state, ...{ targetSystem: input } });
            this.props.setSinkDetails({ targetSystem: input });
        } else if (type === "database") {
            this.setState({ ...this.state, ...{ database: input } });
            this.props.setSinkDetails({ database: input });
        } else if (type === "table") {
            this.setState({ ...this.state, ...{ table: input } });
            this.props.setSinkDetails({ table: input });
        }
    };

    handleChangeFileType = (event) => {
        this.setState({
            ...this.state,
            ...{ fileType: event.target.value },
        });
    };

    render() {
        return (
            <Card sx={{ padding: 5 }}>
                <Typography>
                    In this section, you must provide information about the destination of the
                    data and its consumption interfaces.
                </Typography>
                <br />
                <Typography sx={{ fontStyle: "italic" }}>
                    Selecting an appropriate target system ensures that your Data Product is stored in an
                    environment conducive to its usage and analysis.
                     This might include an analytical database for complex queries,
                      a database for transactional operations, or a data lake for big data processing.

                On the other hand, choosing the right consumption interfaces ensures your data is accessible and usable.
                These interfaces might range from APIs for application interactions, SQL interfaces for
                 direct querying, or data feeds for batch consumption.
                </Typography>
                                <Typography sx={{ marginTop: 2 }}>
                    Selection of Target System
                </Typography>
                <Divider sx={{ marginBottom: 1 }} />
                <Box>
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <InputLabel>Target System/Format</InputLabel>
                        <Select
                            label="Target system (sink)"
                            onChange={(evt) =>
                                this.handleChange(
                                    evt.target.value,
                                    "targetSystem"
                                )
                            }
                        >
                            <MenuItem value={"s3"}>
                                S3 (default)
                            </MenuItem>
                            <MenuItem value={"iceberg"}>MySQL</MenuItem>
                            <MenuItem value={"mongodb"}>MongoDB</MenuItem>
                            <MenuItem value={"graph-db"}>Some Graph DB</MenuItem>
                            <MenuItem value={"..."}>...</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                
                <Typography sx={{ marginTop: 4 }}>
                    Consumption Interfaces
                </Typography>
                <Divider sx={{ marginBottom: 1 }} />
                <Typography sx={{ marginTop: 4, marginBottom: 4 }}>
                    Depending on the business goal and the requirements of the users, the Data Product
                    should define ways to access the data. E.g. the most simplest form would be to just
                    download the data as csv file.
                </Typography>
                <Container sx={{display: "flex", flexDirection: "column"}}>
                    <FormControlLabel control={<Checkbox />} label="REST API" />
                    <FormControlLabel control={<Checkbox />} label="GraphQL" />
                    <FormControlLabel control={<Checkbox />} label="File Download" />
                    <FormControlLabel control={<Checkbox />} label="Tableau Dashboard" />
                    <FormControlLabel control={<Checkbox />} label="ODBC" />
                    <FormControlLabel control={<Checkbox />} label="SQL (e.g. in form of HUE)" />
                    <FormControlLabel control={<Checkbox />} label="..." />
                </Container>

                {/* {this.state.targetSystem === "presto" &&
                <Box>
                    <Box sx={{marginTop: 2, display: "flex", flexFlow: "row", }}>
                        <TextField
                            defaultValue="mesh_table"
                            variant="outlined"
                            onChange={(evt) =>
                                this.handleChange(evt.target.value, "table")
                            }
                        />
                        <FormControl fullWidth>
                            <InputLabel>Choose Database</InputLabel>
                            <Select
                                label="Choose Database"
                                onChange={(evt) =>
                                    this.handleChange(evt.target.value, "database")
                                }
                            >
                                <MenuItem value={"mesh_db"}>mesh_db</MenuItem>
                                <MenuItem value={"..."}>...</MenuItem>
                            </Select>
                        </FormControl>                        
                    </Box>
                    <Typography>Show here Schema...</Typography>
                    </Box>
                } */}
            </Card>
        );
    }
}

export default ProvisionSinkForm;
