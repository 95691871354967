import React from "react";
import ProductProvisionForm from "../components/ProductProvisionForm";
import { Container, Typography } from "@mui/material";
import {
    Menu as MenuIcon,
    AccountCircle,
    Dvr,
    FileUpload,
} from "@mui/icons-material";

class DataProvisionView extends React.Component {
    constructor() {
        super();
        this.state = { dataProducts: [], detailProduct: undefined };
    }

    render() {
        return (
            <Container class="sub-view">
                <Container id="data-provisioning-head" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FileUpload sx={{ color: "primary.100", marginRight: "20px" }} />
                    <Typography variant="h5">
                        Data Provisioning Formular
                    </Typography>
                </Container>

                <ProductProvisionForm
                    user={this.props.user}
                    token={this.props.token}
                    access_token={this.props.access_token}
                />
            </Container>
        );
    }
}

export default DataProvisionView;
