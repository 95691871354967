/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React, { useDeferredValue } from "react";
import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
    Box,
    Dialog,
    touchRippleClasses,
    Container,
    Breadcrumbs,
    Link,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import ProvisionSourceForm from "./ProvisionSourceForm";
import ProvisionSinkForm from "./ProvisionSinkForm";
import ProvisionETLForm from "./ProvisionETLForm";
import ProvisionMetadataForm from "./ProvisionMetadataForm";
import ProvisionCheckUpForm from "./ProvisionCheckUpForm";
import productService from "../services/DataProductService";
import { styled } from "@mui/system";
import ReactLoading from "react-loading";
import DataProductService from "../services/DataProductService";

import CloseIcon from '@mui/icons-material/Close';

const ProductStack = styled(Stack)(({ theme }) => ({
    "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "border-radius": 2,
        "background-color": "rgb(255,255, 255, 0)",
    },
    "&::-webkit-scrollbar": {
        width: 6,
        "border-radius": 2,
        "background-color": "rgb(255,255, 255, 0)",
    },
    "&::-webkit-scrollbar-thumb": {
        "border-radius": 2,
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        "background-color": theme.palette.primary.main,
    },
}));

class ProductProvisionForm extends React.Component {
    constructor() {
        super();
        this.state = {
            currentStep: "ETLForm",
            showCreationNotification: false,
            dpSourceSystem: undefined,
            dpSourceDetails: undefined,
            dpSinkDetails: undefined,
            dpDb: "defaultMeshDB",
            dpProductDescription: "",
            dpProductType: undefined,
            dpSecurityStandard: undefined,
            dpEtl: {},
            dpProducer: undefined,
            dpPolicies: [],
            dpAdditionalFields: [],
        };

        this.stages = {
            ETLForm: {
                prev: undefined,
                next: "MetadataForm",
                text: "Data Product Type",
            },
            MetadataForm: {
                prev: "ETLForm",
                next: "SourceForm",
                text: "(Business) Metadata",
            },
            SourceForm: {
                prev: "MetadataForm",
                next: "SinkForm",
                text: "Source System",
            },
            SinkForm: {
                prev: "SourceForm",
                next: "CheckUp",
                text: "Consumer Interfaces",
            },
            CheckUp: {
                prev: "SinkForm",
                next: undefined,
                text: "Final Check Up",
            },
        };
    }

    componentDidMount = () => {
        var newProducer = {
            name: this.props.user ? this.props.user : "-",
            department: "BackRub - GO4",
            division: "Executive Management",
            email: "larry.page@backrub.de",
        };
        this.setState(
            (prev) => ((prev.dpProducer = newProducer), prev))
    };
    /**
     *
     * @param {String} sourceSystem
     */
    setSourceSystem = (sourceSystem) => {
        this.setState((prev) => ((prev.dpSourceSystem = sourceSystem), prev));
    };

    setSourceDetails = (sourceDetails) => {
        this.setState((prev) => ((prev.dpSourceDetails = sourceDetails), prev));
    };

    setProducerDetails = (producerDetails) => {
        this.setState((prev) => ((prev.dpProducer = producerDetails), prev));
    };

    setSinkDetails = (sinkDetails) => {
        this.setState((prev) => ((prev.dpSinkDetails = sinkDetails), prev));
    };

    setETLDetails = (key, value) => {
        this.setState((prev) => ((prev[key] = value), prev));
    };

    setMetadataDetails = (key, value) => {
        this.setState((prev) => ((prev[key] = value), prev), () => console.log(this.state));
    };

    inputMetadataChange = (key, value) => {
        this.setState((prev) => ((prev[key] = value), prev), () => console.log(this.state));

    };

    setDataSources = (dataSources) => {
        this.setState((prev) => ((prev.dpDataSources = dataSources), prev));
    };

    deleteAdditionalField = (i) => {
        this.state.dpAdditionalFields.splice(i, 1);
        this.setState((prev) => ((prev.dpAdditionalFields = this.state.dpAdditionalFields), prev));
    };

    changeAdditionalField = (i, key, value) => {
        if (this.state.dpAdditionalFields.length - 1 <= i) {
            this.setState((prev) => ((prev.dpAdditionalFields[i][key] = value), prev));
        } else {
            this.setState((prev) => (prev.dpAdditionalFields.push({ [key]: value }), prev));
        }

    };

    addNewPolicyRule = (newPolicy) => {
        var tmpPolicies = this.state.dpPolicies
        tmpPolicies.push(newPolicy)
        this.setState((prev) => (prev.dpPolicies = tmpPolicies, prev));
    }

    /**
     * This functions triggers the creation process and sends the input data to the
     * corresponding microservice.
     *
     * TODO: Think about data structure - Is there a better structure?
     *
     */
    createDataProduct = () => {
        this.setState({ showLoadingScreen: true });

        // dpDetails now has all parameters for related to the dp
        const { currentStep, showCreationNotification, ...dpDetails } = this.state

        // TODO: We receive a time out since the API gateway has a hard timeout limit at 29 seconds.
        productService
            .createDataProduct({ ...dpDetails, token: this.props.access_token }, this.props.token)
            .then((data) => {
                console.log("Creation process successfully started...");
                this.setState({ showCreationNotification: true, showLoadingScreen: false });
            })
            .catch((err) => { console.log(err); this.setState({ showCreationNotification: true, showLoadingScreen: false }); });
    };

    handleSuccessDialog = () => { };

    getDPDetails() {
        // dpDetails now has all parameters for related to the dp
        const { currentStep, showCreationNotification, ...dpDetails } = this.state
        return dpDetails
    }

    render() {
        return (
            <Container id="provisioning-form">
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />}>
                        {Object.keys(this.stages).map((x) => (
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => {
                                    if (
                                        this.state.currentStep === "MetadataForm" &&
                                        (this.state.dpProductName === "" || !this.state.dpProductName)
                                    ) {
                                        this.setState({
                                            ...this.state,
                                            ...{
                                                dpNameError: "Please enter a name.",
                                            },
                                        });
                                        return;
                                    }

                                    this.setState({
                                        ...this.state,
                                        ...{ currentStep: x },
                                    });
                                }}
                            >
                                {this.stages[x].text}
                            </Link>
                        ))}
                    </Breadcrumbs>

                    <Container
                        sx={{
                            display: "flex",
                            flexFlow: "row",
                            justifyContent: "end",
                            padding: 0,
                        }}
                    >
                        {this.stages[this.state.currentStep].prev && (
                            <Button
                                sx={{
                                    whiteSpace: "nowrap",
                                    minWidth: "max-content",
                                    marginRight: 3,
                                }}
                                onClick={() => {
                                    console.log(this.state);
                                    var tempStep = this.stages[this.state.currentStep].prev;
                                    this.setState({
                                        currentStep: tempStep,
                                    });
                                }}
                            >
                                Previous Step
                            </Button>
                        )}
                        {this.stages[this.state.currentStep].next && (
                            <Button
                                sx={{ whiteSpace: "nowrap" }}
                                variant="outlined"
                                onClick={() => {
                                    console.log(this.state);
                                    if (this.state.currentStep === "SinkForm") {
                                        // Here read out the information form the glue crawler
                                    }

                                    if (
                                        this.state.currentStep === "MetadataForm" &&
                                        (this.state.dpProductName === "" || !this.state.dpProductName)
                                    ) {
                                        this.setState((prev) => ((prev.dpNameError = "Please enter a name."), prev));
                                        return;
                                    }
                                    this.setState({
                                        ...this.state,
                                        ...{
                                            currentStep: this.stages[this.state.currentStep].next,
                                        },
                                    });
                                }}
                            >
                                Next
                            </Button>
                        )}
                        {this.state.currentStep === "CheckUp" && (
                            <div>
                                <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    sx={{
                                        width: "80%",
                                        margin: "auto",
                                        whiteSpace: "nowrap",
                                        minWidth: "max-content",
                                    }}
                                    onClick={this.createDataProduct}
                                >
                                    Create Data Product
                                </Button>
                                <Dialog
                                    open={this.state.showCreationNotification}
                                    onClose={() =>
                                        this.setState({
                                            ...this.state,
                                            ...{
                                                showCreationNotification: !this.state.showCreationNotification,
                                            },
                                        })
                                    }
                                    sx={{ padding: 3 }}
                                >
                                    The creation of the product was initiated! It might take few minutes until it is
                                    done.
                                </Dialog>
                            </div>
                        )}
                    </Container>
                </Container>

                {this.state.showLoadingScreen &&
                    <Container sx={{ zIndex: 2, position: "absolute", top: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", opacity: 0.5, height: "100vh", width: "100vw", maxWidth: "unset !important" }}>
                        <IconButton sx={{ top: "1rem", right: "1rem", position: "absolute" }} onClick={() =>
                            this.setState({ showLoadingScreen: false })} >
                            <CloseIcon />
                        </IconButton>
                        <ReactLoading type={"cylon"} />
                    </Container>
                }

                <ProductStack
                    id="form-element"
                    style={{
                        width: "100%",
                        flexGrow: 1,
                        flexBasis: 0,
                        overflowY: "auto",
                    }}
                >
                    <Typography variant="h5">{this.stages[this.state.currentStep].text}</Typography>
                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    {this.state.currentStep === "MetadataForm" && this.state.dpProducer !== undefined && (
                        <ProvisionMetadataForm
                            producer={this.state.dpProducer}
                            inputMetadataChange={this.inputMetadataChange}
                            dpPolicies={this.state.dpPolicies}
                            dpNameError={this.state.dpNameError}
                            dpName={this.state.dpProductName}
                            dpProductDescription={this.state.dpProductDescription}
                            dpSecurityStandard={this.state.dpSecurityStandard}
                            dpAdditionalFields={this.state.dpAdditionalFields}
                            setMetadataDetails={this.setMetadataDetails}
                            deleteAdditionalField={this.deleteAdditionalField}
                            changeAdditionalField={this.changeAdditionalField}
                            additionalField={this.state.dpAdditionalField}
                            addNewPolicyRule={this.addNewPolicyRule}
                        />
                    )}
                    {this.state.currentStep === "SourceForm" && this.state.dpProducer !== undefined && (
                        <ProvisionSourceForm
                            token={this.props.token}
                            inputMetadataChange={this.inputMetadataChange}
                            producer={this.state.dpProducer}
                            dpProductName={this.state.dpProductName}
                            setSourceSystem={this.setSourceSystem}
                            setSourceDetails={this.setSourceDetails}
                            setProducerDetails={this.setProducerDetails}
                            setDataSources={this.setDataSources}
                            s3URI={this.state.s3URI}
                        />
                    )}
                    {this.state.currentStep === "ETLForm" && this.state.dpProducer && (
                        <ProvisionETLForm
                            processingSystem={
                                this.state.sourceDetails ? this.state.sourceDetails.consumptionSystem : ""
                            }
                            setETLDetails={this.setETLDetails}
                        />
                    )}
                    {this.state.currentStep === "SinkForm" && this.state.dpProducer && (
                        <ProvisionSinkForm setSinkDetails={this.setSinkDetails} />
                    )}

                    {this.state.currentStep === "CheckUp" && this.state.dpProducer !== undefined && (
                        <ProvisionCheckUpForm provisionDetails={this.getDPDetails()} />
                    )}
                </ProductStack>
            </Container>
        );
    }
}

export default ProductProvisionForm;
