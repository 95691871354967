/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React from "react";
import { Button, Card, CardContent, Divider, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Delete, Loop, Add } from "@mui/icons-material";
import PolicyEditor from "./PolicyEditor";
import Attribute from "./Attribute";

class ProvisionMetadataForm extends React.Component {
    constructor() {
        super();
        this.state = {
            dpAdditionalFields: [],
        };
    }


    render() {
        return (
            <Card sx={{ padding: 5 }}>
                <Typography sx={{ fontStyle: "italic" }}>
                    In this section, you must add metadata to your data product and define the policies
                    for access to the product.
                </Typography>
                <br />
                <Typography sx={{ fontStyle: "italic" }}>
                    Metadata, and in particular, business metadata - which encompasses information specifically tailored
                    to address unique business requirements - plays a crucial role in enhancing the quality of the Data
                    Product. Furthermore, business metadata aids in aligning the Data Product with its key attributes
                    such as trustworthiness and accessibility, among others.
                </Typography>
                <Typography sx={{marginTop: 4 }}>Producer Information</Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Attribute name={"Producer Name"} value={this.props.producer.name} />
                <Attribute name={"Departement"} value={this.props.producer.department} />
                <Attribute name={"Division"} value={this.props.producer.division} />
                <Attribute name={"Email"} value={this.props.producer.email} />
                <Attribute name={"Email"} value={this.props.producer.email} />

                <Typography sx={{ marginTop: 4 }}>Business Metadata</Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Attribute
                    key={this.props.dpName}  // Necessary for re-rendering
                    name={"Data Product Name*:"}
                    value={this.props.dpName}
                    textProps={{
                        error: this.props.dpNameError !== undefined,
                        helperText: this.props.dpNameError,
                        onChange: (evt) => this.props.inputMetadataChange("dpProductName", evt.target.value.toLowerCase()),
                        placeholder: "e.g. Sales for Product XYZ",
                        disabled: undefined,
                    }}
                />
                <Attribute
                    key={this.props.dpProductDescription}  // Necessary for re-rendering
                    name={"Description*:"}
                    value={this.props.dpProductDescription}
                    textProps={{
                        onChange: (evt) => this.props.inputMetadataChange("dpProductDescription", evt.target.value),
                        placeholder: "Some description",
                        disabled: undefined,
                        multiline: true,
                    }}
                />
                <Attribute
                    name={"Security Standard*:"}
                    value={this.props.dpSecurityStandard}
                    textProps={{
                        onChange: (evt) => this.props.inputMetadataChange("dpSecurityStandard", evt.target.value),
                        placeholder: "e.g. confidential",
                        disabled: undefined,
                        multiline: true,
                    }}
                />

                <Typography sx={{ fontStyle: "italic" }}>*Required</Typography>

                <Typography sx={{ marginTop: 4 }}>Custom/individual metadata</Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Typography sx={{ marginTop: 4 }}>
                    If necessary one can cover specific metadata by adding individual information in form key value
                    pairs. Those information improve the overall quality of the Data Product and helps to easily provide
                    the information that are really needed.
                </Typography>

                <Button
                    sx={{ marginTop: 4, marginBottom: 4 }}
                    variant="outlined"
                    onClick={() => {
                        this.props.dpAdditionalFields.push({});
                        this.setState(this.state);
                    }}
                >
                    Add Field
                </Button>

                {this.props.dpAdditionalFields &&
                    this.props.dpAdditionalFields.map((x, i) => (
                        <Box
                            key={`extraMetadata-${1}`}
                            sx={{
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 2,
                            }}
                        >
                            <TextField
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                }}
                                size="small"
                                value={x.name}
                                placeholder="Name"
                                variant="outlined"
                                onChange={(evt) => this.props.changeAdditionalField(i, "name", evt.target.value)}
                            />
                            <TextField
                                style={{
                                    flex: 2,
                                    marginLeft: 10,
                                }}
                                multiline
                                value={x.description}
                                size="small"
                                placeholder="Description"
                                variant="outlined"
                                onChange={(evt) => this.props.changeAdditionalField(i, "description", evt.target.value)}
                            />

                            <IconButton
                                aria-label="user"
                                sx={{ order: 2, marginLeft: 3 }}
                                onClick={(evt) => {
                                    this.props.deleteAdditionalField(i);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    ))}

                <Typography sx={{ marginTop: 4 }}>Policy Definition</Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Typography sx={{ marginTop: 4, fontStyle: "italic" }}>
                    To define your polciy pleas use the policy standard language within the data mesh.
                </Typography>
                <PolicyEditor
                    policies={this.props.dpPolicies}
                    setPolicy={(policy) => this.props.setMetadataDetails("dpPolicies", policy)}
                    addNewPolicyRule={this.props.addNewPolicyRule}
                />
                <Divider />
            </Card>
        );
    }
}

export default ProvisionMetadataForm;
