import {
    Container,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Typography,
    Divider,
    Button,
    Box,
    IconButton,
} from "@mui/material";
import React from "react";
import { Delete, Loop, Add } from "@mui/icons-material";
import DataProductService from "../services/DataProductService";
import ReactLoading from "react-loading";

const SchemaRow = (props) => {
    const [state, setState] = React.useState({
        Name: undefined,
        Type: undefined,
        Parameters: {
            constraints: undefined,
        },
        Comment: undefined,
    });

    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 3,
                marginBottom: 3,
            }}
        >
            <TextField
                sx={{ minWidth: 250, marginRight: 2 }}
                onChange={(evt) =>
                    props.changeDetails({ Name: evt.target.value })
                }
                variant="outlined"
                value={props.details ? props.details.Name : ""}
                placeholder={props.details?.Name || "Column Name*"}
            />

            <FormControl fullWidth sx={{ marginRight: 2 }}>
                <InputLabel>Data Type</InputLabel>
                <Select
                    label="Data Type"
                    value={props.details ? props.details.Type : ""}
                    // onChange={(evt) => {setState({Type: evt.target.value})}}
                    onChange={(evt) =>
                        props.changeDetails({ Type: evt.target.value })
                    }
                >
                    <MenuItem value={"int"}>INT</MenuItem>
                    <MenuItem value={"float"}>FLOAT</MenuItem>
                    <MenuItem value={"double"}>DOUBLE</MenuItem>
                    <MenuItem value={"string"}>STRING</MenuItem>
                    <MenuItem value={"list"}>LIST</MenuItem>
                    <MenuItem value={"object"}>object</MenuItem>
                    <MenuItem value={"string"}>VARCHAR</MenuItem>
                    <MenuItem value={"bigint"}>BIGINT</MenuItem>
                    <MenuItem value={"boolean"}>BOOLEAN</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginRight: 2 }}>
                <InputLabel>Constraints</InputLabel>
                <Select
                    label="Constraints"
                    value={props.details ? props.details.Parameters?.constraints : ""}
                    // onChange={(evt) => {setState({constraints: evt.target.value})}}
                    onChange={(evt) =>
                        props.changeDetails({ Parameters: {constraints: evt.target.value} })
                    }
                >
                    <MenuItem value={"notNull"}>Not Null</MenuItem>
                    <MenuItem value={".."}>...</MenuItem>
                </Select>
            </FormControl>

            <TextField
                sx={{ minWidth: 250 }}
                multiline
                value={props.details ? props.details.Comment : ""}
                // onChange={(evt) => setState({Comment: evt.target.value})}
                onChange={(evt) =>
                    props.changeDetails({ Comment: evt.target.value })
                }
                variant="outlined"
                placeholder={props.details?.Comment || "Comment*"}
            />
            {props.enableDeletion && (
                <IconButton
                    aria-label="user"
                    sx={{ order: 2, marginLeft: 3 }}
                    onClick={(evt) => props.changeDetails(undefined)}
                >
                    <Delete />
                </IconButton>
            )}
        </Container>
    );
};

class DataProductSchemaEditor extends React.Component {
    constructor() {
        super();
        this.state = {
            schema: [],
            newSchemaObject: undefined,
            unsavedChanges: false,
            loading: false,
        };
    }

    componentDidMount = () => {
        this.loadSchema();
    };

    loadSchema = () => {
        DataProductService.getDataProductSchema(
            { dp_name: this.props.dataProductName },
            this.props.token
        )
            .then((result) => {
                console.log(JSON.parse(result.body));
                this.setState({ schema: JSON.parse(result.body) });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    saveSchema = () => {
        this.setState({ loading: true });
        DataProductService.saveDataProductSchema(
            { dp_name: this.props.dataProductName, schema: this.state.schema },
            this.props.token
        )
            .then(() => {
                this.setState({ unsavedChanges: false, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    applySchema = () => {
        this.setState({ loading: true });
        DataProductService.applyDataProductSchema(
            { dp_name: this.props.dataProductName, schema: this.state.schema },
            this.props.token
        )
            .then((result) => {
                console.log(result)
                this.setState({ unsavedChanges: false, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    changeSchemaDetails = (i, details) => {
        var tmpSchema = this.state.schema;

        if (details == undefined) {
            tmpSchema.splice(i, 1);
        } else {
            tmpSchema[i] = { ...tmpSchema[i], ...details };
        }

        this.setState({ schema: tmpSchema, unsavedChanges: true });
    };

    render() {
        return (
            <Container>
                {this.state.loading && (
                    <Container
                        sx={{
                            backgroundColor: "grey",
                            opacity: 0.5,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            zIndex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ReactLoading type={"cylon"} />
                    </Container>
                )}

                <Typography variant="h5">Edit Schema</Typography>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography>
                        Please define or select the schema for your final data
                        product.
                    </Typography>
                    <Button variant="contained">
                        Select Schema from Registry
                    </Button>
                </Container>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography>The existing schema:</Typography>
                    <IconButton
                        sx={{ marginLeft: 2 }}
                        onClick={this.loadSchema}
                    >
                        <Loop />
                    </IconButton>
                </Box>

                <Box sx={{ overflowX: "auto", height: "60vh" }}>
                    {this.state.schema?.map((s, i) => (
                        <SchemaRow
                            details={s}
                            changeDetails={(newDetails) =>
                                this.changeSchemaDetails(i, newDetails)
                            }
                            enableDeletion={true}
                        />
                    ))}
                </Box>

                <Divider />
                <Typography>Add new column.</Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <SchemaRow
                        details={this.state.newSchemaObject}
                        changeDetails={(newDetails) =>
                            this.setState({
                                newSchemaObject: {
                                    ...this.state.newSchemaObject,
                                    ...newDetails,
                                },
                                unsavedChanges: true,
                            })
                        }
                    />
                    <IconButton
                        sx={{ order: 2, marginLeft: 3 }}
                        onClick={() =>
                            this.setState({
                                schema: this.state.schema.concat(
                                    this.state.newSchemaObject
                                ),
                                newSchemaObject: undefined,
                            })
                        }
                    >
                        <Add />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => this.saveSchema()}
                        >
                            Save schema
                        </Button>
                        {this.state.unsavedChanges && (
                            <Typography sx={{ marginLeft: 2 }}>
                                Unsaved changes!
                            </Typography>
                        )}
                    </Box>

                    <Button
                        variant="contained"
                        onClick={() => this.applySchema()}
                    >
                        Apply schema
                    </Button>
                </Box>
            </Container>
        );
    }
}

export default DataProductSchemaEditor;
