import {
    Typography,
    Container,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material";
import React from "react";
import ReactLoading from "react-loading";

import Attribute from "./Attribute";

import DataProductService from "../services/DataProductService";

class RequestForm extends React.Component {
    constructor() {
        super();
        this.state = {
            requestedRole: "",
        };
    }

    requestAccess() {
        if (!this.state.requestedRole || this.state.requestedRole === "") {
            this.setState({ requestedRoleError: "Please select a role." });
            return;
        }

        this.setState({ requestedRoleError: undefined, loading: true });

        DataProductService.requestAccess(
            this.props.productName,
            this.props.user,
            this.props.accessToken,
            this.state.requestedRole,
            this.props.token
        )
            .then((res) => {
                console.log(res);
                this.setState({ loading: false });
                this.props.reload()
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false });
                this.props.reload()
            });
    }

    render() {
        return (
            <Container>
                {this.state.loading && (
                    <Container
                        sx={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            backgroundColor: "rgba(0,0,0,0.6)",
                            padding: "0px !important",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <ReactLoading type={"cylon"} />{" "}
                    </Container>
                )}

                <Typography variant="h5">Request Form</Typography>
                <Typography>
                    For requesting access to the data product please enter the necessary information and send it.
                </Typography>

                <Divider sx={{ marginTop: 2, marginBottom: 2 }} flexItem />

                <Container>
                    <Attribute name="Reqest for:" textProps={{ disabled: false, placeholder: "E.g. some user name" }} />
                    <Attribute
                        name="Planned usage:"
                        textProps={{
                            disabled: false,
                            placeholder: "E.g. explorative analysis or reporting",
                            multiline: true,
                            rows: 2,
                        }}
                    />
                    <Attribute
                        name="Special requirements:"
                        textProps={{
                            disabled: false,
                            placeholder: "E.g. uptime from Monday to Friday at least from 9:00 - 17:00.",
                            multiline: true,
                        }}
                    />

                    <FormControl size="small" sx={{ minWidth: 150 }} error={this.state.requestedRoleError}>
                        <InputLabel>Role</InputLabel>
                        <Select
                            value={this.state.requestedRole}
                            label="Role"
                            onChange={(evt) => this.setState({ requestedRole: evt.target.value })}
                        >
                            <MenuItem value={"read"}>Read</MenuItem>
                            <MenuItem value={"read-write"}>Read/Write</MenuItem>
                            <MenuItem value={"engineer"}>Engineer</MenuItem>
                        </Select>
                        {this.state.requestedRoleError && (
                            <FormHelperText>{this.state.requestedRoleError}</FormHelperText>
                        )}
                    </FormControl>
                </Container>

                <Divider sx={{ marginTop: 2, marginBottom: 2 }} flexItem />
                <Container sx={{ display: "flex", flexDirection: "row" }}>
                    <Button variant="contained" onClick={() => this.requestAccess()}>
                        Send Request
                    </Button>
                </Container>
            </Container>
        );
    }
}
export default RequestForm;
