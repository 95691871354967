import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
    Card,
    Collapse,
    Divider,
} from "@mui/material";
import {
    Menu as MenuIcon,
    AccountCircle,
    Dvr,
    ExpandMore,
    LockOpen,
    Input,
    ContactPage,
    FilterAlt,
} from "@mui/icons-material";
import React from "react";
import DataProductCard from "../components/DataProductCard";
import { styled } from "@mui/system";
import config from "../config";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DataProductDetails from "../components/DataProductDetails";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ReactLoading from "react-loading";

const UserService = require("../services/UserService");

// const dataProductBackground = "../assets/dataProductBackground.jpg"

const Item = styled("div")(({ theme }) => ({
    // padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // border: "solid 1px",
    // borderRadius: 3,
    // borderColor: "#78909c",
}));

const ProductStack = styled(Stack)(({ theme }) => ({
    "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "border-radius": 2,
        "background-color": "rgb(255,255, 255, 0)",
    },
    "&::-webkit-scrollbar": {
        width: 6,
        "border-radius": 2,
        "background-color": "rgb(255,255, 255, 0)",
    },
    "&::-webkit-scrollbar-thumb": {
        "border-radius": 2,
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        "background-color": theme.palette.primary.main,
    },
}));

class DataProductView extends React.Component {
    constructor() {
        super();
        this.state = {
            dataProducts: [],
            detailProduct: undefined,
            permissionRequestMessage: undefined,
            expanded: [],
            searchString: "",
        };
    }

    componentDidMount() {
        this.getDataProducts();
    }

    reload = () => {
        this.setState({ dataProducts: [] })
        this.getDataProducts();
    }

    getDataProducts = () => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/catalog/list-data-products`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                console.log(JSON.parse(json.body)[0]);
                this.setState({
                    ...this.state,
                    ...{ dataProducts: JSON.parse(json.body) },
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    showProductDetails = (product) => {
        this.setState({
            detailProduct: product,
        });
    };


    handleSearchStringChange = (e) => {
        this.setState({
            ...this.state,
            ...{ searchString: e.target.value },
        });
    };

    render() {
        // console.log(`url(${dataProductBackground})`)
        return (
            <Container class="subView" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <Container sx={{ marginBottom: 3, marginTop: 3 }}>
                    <Typography>Data Product List</Typography>
                    <Typography>
                        We have{" "}
                        {
                            this.state.dataProducts.filter(
                                (x) => x.Parameters && x.Parameters.dp_state != "development" &&
                                    x.StorageDescriptor.Location.includes("prod")
                            ).length
                        }{" "}
                        data products available.
                    </Typography>
                    <Typography>...</Typography>
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            label="Search"
                            size="small"
                            variant="outlined"
                            value={this.state.searchString}
                            onChange={this.handleSearchStringChange}
                            sx={{ width: 400 }}
                        />
                        <Button variant="outlined" startIcon={<FilterAlt />}>
                            Filter
                        </Button>
                    </Container>
                </Container>

                {/* TODO: Adjust the filter operation */}
                {this.state.dataProducts.length > 0 &&
                    !this.state.loadDataProducts &&
                    this.state.dataProducts
                        .filter(
                            (x) =>
                                x.Parameters &&
                                x.Parameters.dp_state != "development" &&
                                x.Name.includes(this.state.searchString) &&
                                x.StorageDescriptor.Location.includes("prod")
                        )
                        .map((x, i) => (
                            <DataProductCard
                                dataProduct={x}
                                idx={i}
                                token={this.props.token}
                                access_token={this.props.access_token}
                                user={this.props.user}
                                reload={this.reload}
                            />
                        ))}
            </Container>
        );
    }
}
//     <div
//         style={{
//             ...this.props.style,
//             ...{
//                 position: "relative",
//                 display: "flex",
//                 height: "100vh",
//                 flexFlow: " column",
//                 alignContent: "baseline",
//             },
//         }}
//     >
//         <div
//             style={{
//                 // backgroundImage: `url(${dataProductBackground})`,
//                 height: "20vh", //"60vh",
//                 width: "calc(100vw - 0px)",
//                 backgroundPosition: "50% 20%",
//                 backgroundSize: "cover",
//                 padding: 20,
//                 position: "-webkit-sticky",
//                 position: "sticky",
//                 top: 0, //"-40vh",
//                 zIndex: 2,
//                 flexShrink: 0,
//             }}
//         >
//             <h3 style={{ position: "absolute", bottom: 0, left: 20 }}>Data Product Catalog</h3>
//         </div>

//         <Box
//             sx={{
//                 padding: 3,
//                 width: this.state.detailProduct !== undefined ? "50vw" : "calc(100vw - 48px)",
//                 display: "flex",
//                 alignItems: "center",
//                 flexFlow: "column",
//                 flex: 1,
//                 alignContent: "baseline",
//             }}
//         >
//             <div
//                 style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     marginBottom: 20,
//                     flexShrink: 0,
//                 }}
//             >
//                 {/* <Button variant="contained" startIcon={<AddIcon />}>
//                     Add Data Product
//                 </Button> */}
//                 <TextField
//                     size="small"
//                     sx={{ minWidth: 450 }}
//                     id="outlined-basic"
//                     label="Search Catalog"
//                     variant="outlined"
//                 />
//                 <IconButton aria-label="refresh" onClick={() => this.getDataProducts()}>
//                     <RefreshIcon />
//                 </IconButton>
//             </div>

//             {(this.state.dataProducts.length === 0 || this.state.loadDataProducts) &&
//                 <ReactLoading type={"cylon"} />
//             }

//             {(this.state.dataProducts.length > 0 && !this.state.loadDataProducts) &&
//                 <ProductStack spacing={2} sx={{ padding: 2, overflowY: "auto", minWidth: "50vw", flexGrow: 1, flexBasis: 0 }}>
//                     {this.state.dataProducts.map((x) => (
//                         <div>
//                             <DataProductCard
//                                 userAccessPermissions={this.props.user.permissions}
//                                 user={this.props.user}
//                                 product={x}
//                                 showDetails={this.showProductDetails}
//                                 requestAccess={this.requestAccess}
//                                 updateUserData={this.props.updateUserData}
//                                 refreshDataProductCatalog={this.getDataProducts}
//                             />
//                         </div>
//                     ))}
//                 </ProductStack>
//             }
//         </Box>

//         <Box
//             sx={{
//                 width: this.state.detailProduct !== undefined ? "50vw" : 0,
//                 position: "absolute",
//                 right: 0,
//                 height: "80vh",
//                 top: "50%",
//                 overflow: "auto",
//                 zIndex: 3,
//                 transform: "translateY(-50%)",
//                 backgroundColor: "#121212",
//                 padding: this.state.detailProduct !== undefined ? 3 : 0,
//                 overflow: "hidden",
//                 display: "flex",
//                 alignItems: "center",
//                 flexFlow: "column",
//             }}
//         >
//             <Box
//                 style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     marginBottom: 20,
//                     flexShrink: 0,
//                 }}
//             >
//                 <Tabs>
//                     <Tab label="Product 1" />
//                     <Tab label="Product 2" />
//                     <Tab label="Product 3" />
//                 </Tabs>
//                 <TextField
//                     size="small"
//                     sx={{ minWidth: 450 }}
//                     id="outlined-basic"
//                     label="Search Metadata"
//                     variant="outlined"
//                 />
//                 <IconButton
//                     aria-label="refresh"
//                     onClick={() => {
//                         this.setState({
//                             detailProduct: undefined,
//                         });
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             </Box>
//             {this.state.detailProduct !== undefined && (
//                 <ProductStack spacing={2} sx={{ padding: 3, overflowY: "auto", height: "100%", width: "100%" }}>
//                     <DataProductDetails product={this.state.detailProduct} />
//                 </ProductStack>
//             )}
//         </Box>
//     </div>
// );

export default DataProductView;
