import React from "react";
import {
    Container,
    SwipeableDrawer,
    Button,
    Box,
    Divider,
    Typography,
    IconButton,
    Badge,
    Menu,
    MenuItem,
    Card,
} from "@mui/material";
import { Menu as MenuIcon, AccountCircle, Dvr, FileUpload, Category, Home } from "@mui/icons-material";
import DataProvisionView from "./DataProvisionView";
import DataProductView from "./DataProductView";
import DataProductManagementView from "./DataProductManagementView";
import StartView from "./StartView";

const dataProductBackground = "./assets/dataProductBackground.jpg";

class MainView extends React.Component {
    constructor() {
        super();
        this.state = {
            drawerOpen: false,
            page: "start",
            userAccessPermissions: ["customer_spending_score"],
            currentUser: undefined,
            accessMenuOpen: false,
            anchorAccessMenu: null,
            userService: undefined,
            requestData: undefined,
            numberOpenRequests: 0,
            checkAndProcessDialog: false,
        };
    }

    handleClick = (event) => {
        this.setState({
            accessMenuOpen: true,
            anchorAccessMenu: event.currentTarget,
        });
    };
    handleClose = () => {
        this.setState({ accessMenuOpen: false, anchorAccessMenu: null });
    };

    toggleDrawer = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    };

    render() {
        return (
            <Container class="view" sx={{ width: "80%", height: "100vh", display: "flex", flexDirection: "column" }}>
                <Container
                    id={"head"}
                    sx={{
                        // height: "20vh",
                        width: "100%",
                        backgroundImage: `url(${dataProductBackground})`,
                        padding: "0px !important",
                    }}
                >
                    <Card
                        color="secondary.light"
                        sx={{
                            margin: "5px",
                            width: "100%",
                            padding: "5px",
                            display: "flex",
                            alignItem: "baseline",
                        }}
                    >
                        <Container sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton onClick={this.toggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                            <Typography></Typography>
                        </Container>
                        <Badge
                            sx={{ order: 2, marginLeft: "auto" }}
                            badgeContent={this.state.numberOpenRequests}
                            color="primary"
                        >
                            <IconButton
                                aria-label="user"
                                sx={{ order: 2, marginLeft: "auto" }}
                                onClick={this.handleClick}
                            >
                                <AccountCircle />
                            </IconButton>
                        </Badge>
                        <Menu
                            id="basic-menu"
                            anchorEl={this.state.anchorAccessMenu}
                            open={this.state.accessMenuOpen}
                            onClose={this.handleClose}
                            // MenuListProps={{
                            //     "aria-labelledby": "basic-button",
                            // }}
                        >
                            <MenuItem
                                onClick={() =>
                                    this.setState({
                                        ...this.state,
                                        ...{ showUserDetails: true },
                                    })
                                }
                            >
                                Show User Details
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    this.setState({
                                        ...this.state,
                                        ...{ showOpenRequests: true },
                                    })
                                }
                            >{`Show Requests (Open: ${
                                this.state.numberOpenRequests ? this.state.numberOpenRequests : 0
                            })`}</MenuItem>
                        </Menu>
                    </Card>
                </Container>
                <Container id={"body"} sx={{ width: "100%", overflow: "auto" }}>
                    <SwipeableDrawer
                        anchor={"left"}
                        open={this.state.drawerOpen}
                        onClose={this.toggleDrawer}
                        onOpen={this.toggleDrawer}
                    >
                        <Container sx={{ padding: 4, display: "flex", flexDirection: "column", height: "100vh", justifyContent: "center"}}>
                            <Divider orientation="horizontal" textAlign="left">
                                <b>Data Mesh Showcase</b>
                            </Divider>
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                <Typography sx={{ width: "300px" }}>
                                    This demonstration highlights both the theoretical and practical facets of a
                                    Data Mesh. It aims to show how its diverse elements interact and how they
                                    collectively enable the realization of the Data Mesh's benefits.
                                </Typography>
                            </Box>

                            <Divider orientation="horizontal" textAlign="left">
                                Services
                            </Divider>
                            <Container sx={{ padding: "0 !important", flexGrow: 1 }}>
                                <Button
                                    startIcon={<Home />}
                                    sx={{
                                        margin: 2,
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    margin="normal"
                                    onClick={() => {
                                        this.setState({ page: "start" });
                                    }}
                                >
                                    Start Menu
                                </Button>

                                <Button
                                    startIcon={<Dvr />}
                                    sx={{
                                        margin: 2,
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    margin="normal"
                                    onClick={() => {
                                        this.setState({ page: "dataProduct" });
                                    }}
                                >
                                    Data Product Catalog
                                </Button>

                                <Button
                                    startIcon={<FileUpload />}
                                    sx={{
                                        margin: 2,
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    margin="normal"
                                    onClick={() => {
                                        this.setState({ page: "dataProvision" });
                                    }}
                                >
                                    Data Product Creation
                                </Button>
                                <Button
                                    startIcon={<Category />}
                                    sx={{
                                        margin: 2,
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    margin="normal"
                                    onClick={() => {
                                        this.setState({ page: "dataProductManagement" });
                                    }}
                                >
                                    My Data Products
                                </Button>
                            </Container>
                            <Typography></Typography>
                        </Container>
                    </SwipeableDrawer>

                    {this.state.page === "start" && <StartView token={this.props.token} mainView={this} />}

                    {this.state.page === "dataProvision" && (
                        <DataProvisionView user={this.props.user} token={this.props.token}  access_token={this.props.access_token}/>
                    )}

                    {this.state.page === "dataProduct" && (
                        <DataProductView token={this.props.token} access_token={this.props.access_token} user={this.props.user} />
                    )}

                    {this.state.page === "dataProductManagement" && (
                        <DataProductManagementView user={this.props.user} access_token={this.props.access_token}  token={this.props.token} />
                    )}
                </Container>
            </Container>
        );
    }
}

export default MainView;
