import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Box,
    Container,
    Chip,
    LinearProgress,
    Grid,
    Stack,
    TextField,
    Collapse,
    Switch,
} from "@mui/material";
import React from "react";
import ETLAccessMenu from "./ETLAccessMenu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactLoading from "react-loading";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { styled } from "@mui/system";
import LockIcon from "@mui/icons-material/Lock";
import DoneIcon from "@mui/icons-material/Done";
import {
    RocketLaunch,
    Addchart,
    Launch,
    AppsOutage,
    Loop,
    Autorenew,
    Gavel,
    ExpandMore,
    HelpOutline,
} from "@mui/icons-material";
import DataProductSchemaEditor from "./DataProductSchemaEditor";
import PolicyEditor from "./PolicyEditor";
import Attribute from "./Attribute";

class MyDataProductCard extends React.Component {
    constructor() {
        super();
        this.state = {
            accessMenuOpen: false,
            qualityDialogOpen: false,
            globalPolicyResults: [],
            localPolicyResults: [],
            qualityCheckPassed: false,
            anchorAccessMenu: undefined,
            dataLoaded: false,
            dpInfo: undefined,
            openEditSchemaDialog: false,
            openEditPolicyDialog: false,
            policies: [],
            monitoringLoggingExpanded: false,
            openCharacteristicsDialog: false,
            forceProvision: false
        };

        this.getPolicyResults = this.getPolicyResults.bind(this)
    }

    componentDidMount = () => {
        // this.checkDataLoadStatus();
        this.loadLocalPolicies();
    };

    checkDataLoadStatus = () => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-data-product-info`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
            // TODO: Hard coded
            body: JSON.stringify({
                body: {
                    dp_name: this.props.details["Name"],
                    // source_uri: "s3://mesh-s3-source/mmsr/mmsr_09_2022.csv",
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                var data = JSON.parse(json["body"]);
                var numCrawls = data["crawls"]["Crawls"].length;
                // var summary = {}
                // if(data["crawls"]["Crawls"][numCrawls - 1]) {
                //     summary = JSON.parse(data["crawls"]["Crawls"][numCrawls - 1]["Summary"]);
                //     data["crawls"]["Crawls"][numCrawls - 1]["Summary"] = summary;
                // }

                var firstCrawl = data["crawls"]["Crawls"][numCrawls - 1];

                this.setState({
                    ...this.state,
                    ...{
                        dpInfo: data,
                        dataLoaded: firstCrawl["State"] === "COMPLETED",
                    },
                });
            });
    };

    loadLocalPolicies = () => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/catalog/list-policies`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
            // TODO: Hard coded
            body: JSON.stringify({
                body: {
                    dp_name: this.props.details["Name"],
                    type: "local",
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => this.setState({ policies: JSON.parse(json.body) }));
    };

    loadData = () => {
        var data_sources = [];

        if (this.props.details["Parameters"]["dp_sources"]) {
            data_sources = this.props.details["Parameters"]["dp_sources"].split(",");
        }


        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/load-data`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
            // TODO: Hard coded
            body: JSON.stringify({
                body: {
                    dp_name: this.props.details["Name"],
                    source_uri: "s3://mesh-s3-source/mmsr/mmsr_09_2022.csv",
                    data_sources: data_sources,
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => {});
    };

    provisionDataProduct = () => {
        // TODO: separate in service script
        fetch(
            `https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/create-data-product/provision-data-product`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    degheader: this.props.token,
                },
                // TODO: Hard coded
                body: JSON.stringify({
                    body: {
                        dp_name: this.props.details["Name"],
                        source_uri: "s3://mesh-s3-source/mmsr/mmsr_09_2022.csv",
                    },
                }),
            }
        )
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    ...this.state,
                    ...{ dataProducts: JSON.parse(json.body) },
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    getPolicyResults = (type,name,localPolicys=false) => {
        fetch(`https://8kuocyr3g9.execute-api.eu-central-1.amazonaws.com/dev/get-data-quality`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                degheader: this.props.token,
            },
            body: JSON.stringify({
                body: {
                    dp_name: this.props.details["Name"],
                    token: this.props.access_token,
                    policy_type: type,
                    policy_name: name,
                },
            }),
        })
            .then((res) => res.json())
            .then((json) => {

                if(localPolicys && this.state.qualityDialogOpen){
                    this.state.localPolicyResults = JSON.parse(json.body);
                    console.log(this.state.localPolicyResults);
                    this.refreshQualityDialog();
                }
                else if( !localPolicys && this.state.qualityDialogOpen){
                    this.state.globalPolicyResults = JSON.parse(json.body);
                    console.log(this.state.globalPolicyResults);
                    this.refreshQualityDialog();
                }


                var temp = true;
                this.state.globalPolicyResults.forEach((elem) => {
                    if (!elem.passed && elem.prio === "1") {
                        temp = false;
                    }
                });
                this.state.localPolicyResults.forEach((elem) => {
                    if (!elem.passed && elem.prio === "1") {
                        temp = false;
                    }
                });

                if(this.state.forceProvision){
                    this.setState({ ...this.state, ...{ qualityCheckPassed: true } });
                }
                else{
                    this.setState({ ...this.state, ...{ qualityCheckPassed: temp } });
                }
            })
            .catch((error) => {
                console.log(error);

                this.setState({ qualityCheckPassed: true });
            });
    };

    showQualityDialogOpen = (dialogTextName = "default") => {
        if (this.state.qualityDialogOpen) {
            this.state.localPolicyResults = [];
            this.state.globalPolicyResults = [];
            console.log("clearing results");
        }
        if (!this.state.qualityDialogOpen) {
            this.state.localPolicyResults = [];
            this.state.globalPolicyResults = [];
            this.getPolicyResults("multiple_domain","global");
            this.getPolicyResults("dp_name",this.props.details["Name"],true);

            console.log("getting results");
        }
        this.setState({ ...this.state, ...{ qualityDialogOpen: !this.state.qualityDialogOpen } });
    };

    refreshQualityDialog = () => {
        if (this.state.qualityDialogOpen) {
            this.setState({ ...this.state, ...{ qualityDialogOpen: true } });
        }
    };

    handleClick = (event, type = "accessMenu") => {
        if (type === "accessMenu") {
            this.setState({
                accessMenuOpen: true,
                anchorAccessMenu: event.currentTarget,
            });
        } else if (type == "permissionMenu") {
            this.setState({
                permissionMenuOpen: true,
                anchorPermissionMenu: event.currentTarget,
            });
        }
    };

    handleClose = (type = "accessMenu") => {
        if (type === "accessMenu") {
            this.setState({ accessMenuOpen: false, anchorAccessMenu: null });
        } else if (type == "permissionMenu") {
            this.setState({
                permissionMenuOpen: false,
                anchorPermissionMenu: null,
            });
        }
    };

    handleChange = (input, key) => {
        this.setState({ ...this.state, ...{ [key]: input } });
    };

    handleForceSwitch = (event) => {
        this.setState({forceProvision: event.target.checked})
    }

    addNewPolicyRule = (newPolicy) => {
        var tmpPolicies = this.state.policies;
        tmpPolicies.push(newPolicy);
        this.setState((prev) => ((prev.policies = tmpPolicies), prev));
    };

    render() {
        return (
            <Card
                sx={{
                    ...{
                        margin: 1,
                        width: "100%",
                        minHeight: "350px",
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        position: "relative",
                    },
                    ...this.props.sx,
                }}
            >
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 !important",
                    }}
                >
                    <Typography variant="h5">
                        Name: <b>{this.props.details["Name"]}</b>
                    </Typography>
                    <div>
                        <IconButton>
                            <Launch />
                        </IconButton>
                        <IconButton onClick={() => this.setState({openCharacteristicsDialog: !this.state.openCharacteristicsDialog})} >
                            <HelpOutline />
                        </IconButton>
                    </div>
                </Container>

                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                <CardContent
                    sx={{
                        display: "flex",
                        alignItems: "self-start",
                        flex: 1,
                        padding: 0,
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ fontStyle: "italic" }}>{this.props.details["description"]}</Typography>

                    <Container sx={{ marginBottom: 2 }}>
                        <Typography>Data tables:</Typography>
                        <Container>
                            {this.props.details["Parameters"]["dp_sources"]?.split(",").map((d) => (
                                <TextField
                                    disabled
                                    style={{
                                        margin: 2,
                                        width: "100% !important",
                                    }}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={`${this.props.details["Name"]}_${
                                        d.split("/").slice(-1)[0].split(".")[0]
                                    }`}
                                />
                            ))}

                            {(this.props.details["Parameters"]["dp_sources"] === undefined ||
                                this.props.details["Parameters"]["dp_sources"].length <= 0) && (
                                <Typography sx={{ fontFamily: "italic" }}>No data tables available.</Typography>
                            )}
                        </Container>
                    </Container>
                </CardContent>

                <Container sx={{ marginBottom: 2 }}>
                    <Typography>Actions:</Typography>

                    <Button
                        variant="contained"
                        startIcon={<Autorenew />}
                        sx={{
                            whiteSpace: "nowrap",
                            minWidth: "auto",
                            margin: "5px",
                        }}
                        onClick={(event) => this.loadData()}
                    >
                        Load Data
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<AppsOutage />}
                        sx={{
                            whiteSpace: "nowrap",
                            minWidth: "auto",
                            margin: "5px",
                        }}
                        onClick={(event) => this.setState({ openEditSchemaDialog: true })}
                    >
                        Schema
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<Gavel />}
                        sx={{
                            whiteSpace: "nowrap",
                            minWidth: "auto",
                            margin: "5px",
                        }}
                        onClick={(event) => this.setState({ openEditPolicyDialog: true })}
                    >
                        Policy
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<Addchart />}
                        sx={{
                            whiteSpace: "nowrap",
                            minWidth: "auto",
                            margin: "5px",
                        }}
                        onClick={(event) => this.handleClick(event, "accessMenu")}
                    >
                        Engineering
                    </Button>
                </Container>

                <Container sx={{ marginBottom: 2 }}>
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 !important",
                        }}
                    >
                        <IconButton
                            aria-label="user"
                            sx={{ order: 2, marginLeft: "auto" }}
                            onClick={() =>
                                this.setState({ monitoringLoggingExpanded: !this.state.monitoringLoggingExpanded })
                            }
                        >
                            <ExpandMore />
                        </IconButton>

                        <Typography>Monitoring & Logging:</Typography>
                    </Container>

                    <Collapse in={this.state.monitoringLoggingExpanded} timeout="auto" unmountOnExit>
                        <Attribute name="Last update:" value="Some Date" />
                        <Attribute name="Registered user:" value="E.g. 1.298" />
                    </Collapse>
                </Container>

                <Dialog
                    open={this.state.qualityDialogOpen}
                    onClose={() => this.showQualityDialogOpen()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle>Data Policies Check</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography></Typography>
                        </DialogContentText>

                        <Stack direction="row">

                            <Container sx={{ flexDirection: "row" }}>

                                <Typography variant="h5">Global Policies</Typography>

                                {this.state.globalPolicyResults.length === 0 && <ReactLoading type={"cylon"} />}

                                <Divider
                                    style={{
                                        width: "100%",
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                />
                                <Container>


                                    {this.state.globalPolicyResults.length > 0 &&
                                        this.state.globalPolicyResults.map((data) => (
                                            <div>
                                                <Grid>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Stack sxs={{ marginTop: "20px", width: "300px" }}>
                                                            <Typography variant="h6">{data.policyName}</Typography>

                                                            {data.details != "-" && (
                                                                <Typography sx={{ fontStyle: "italic", fontSize: "7" }}>
                                                                    {data.details}
                                                                </Typography>
                                                            )}
                                                        </Stack>

                                                        <Stack
                                                            sx={{ marginLeft: "50px", minWidth: "130px" }}
                                                            alignItems="flex-end"
                                                        >
                                                            {!data.passed && data.prio === "1" && (
                                                                <CloseIcon
                                                                    sx={{
                                                                        color: "#FF505F",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data.passed && (
                                                                <CheckIcon
                                                                    sx={{
                                                                        color: "#2CCD78",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}
                                                            {!data.passed && data.prio === "2" && (
                                                                <WarningAmberIcon
                                                                    sx={{
                                                                        color: "#FFC107",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}

                                                            {data.passed && <Typography color="#2CCD78">PASSED</Typography>}
                                                            {!data.passed && data.prio === "1" && (
                                                                <Typography color="#FF505F">NOT PASSED</Typography>
                                                            )}

                                                            {!data.passed && data.prio === "2" && (
                                                                <Typography color="#FFC107">NOT PASSED</Typography>
                                                            )}
                                                        </Stack>
                                                    </Stack>

                                                    <Container sx={{ marginTop: "20px", marginLeft: "-20px" }}>
                                                        {data.maxScore > 1 && (
                                                            <Stack direction="row">
                                                                <LinearProgress
                                                                    sx={{
                                                                        minWidth: "380px",
                                                                        height: "10px",
                                                                        marginTop: "10px",
                                                                        marginRight: "20px",
                                                                    }}
                                                                    variant="determinate"
                                                                    value={(data.score * 100) / data.maxScore}
                                                                />

                                                                <Typography fontSize="Large">
                                                                    {data.score}/{data.maxScore}
                                                                </Typography>
                                                            </Stack>
                                                        )}
                                                    </Container>
                                                </Grid>

                                                <Divider
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 2,
                                                        marginBottom: 2,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </Container>
                            </Container>

                            
                            <Container>

                                <Typography variant="h5">Local Policies</Typography>

                                {this.state.localPolicyResults.length === 0 && <ReactLoading type={"cylon"} />}

                                <Divider
                                    style={{
                                        width: "100%",
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                />
                                <Container>


                                    {this.state.localPolicyResults.length > 0 &&
                                        this.state.localPolicyResults.map((data) => (
                                            <div>
                                                <Grid>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Stack sxs={{ marginTop: "20px", width: "300px" }}>
                                                            <Typography variant="h6">{data.policyName}</Typography>

                                                            {data.details != "-" && (
                                                                <Typography sx={{ fontStyle: "italic", fontSize: "7" }}>
                                                                    {data.details}
                                                                </Typography>
                                                            )}
                                                        </Stack>

                                                        <Stack
                                                            sx={{ marginLeft: "50px", minWidth: "130px" }}
                                                            alignItems="flex-end"
                                                        >
                                                            {!data.passed && data.prio === "1" && (
                                                                <CloseIcon
                                                                    sx={{
                                                                        color: "#FF505F",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data.passed && (
                                                                <CheckIcon
                                                                    sx={{
                                                                        color: "#2CCD78",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}
                                                            {!data.passed && data.prio === "2" && (
                                                                <WarningAmberIcon
                                                                    sx={{
                                                                        color: "#FFC107",
                                                                        fontSize: "60px",
                                                                        marginTop: "1px",
                                                                    }}
                                                                />
                                                            )}

                                                            {data.passed && <Typography color="#2CCD78">PASSED</Typography>}
                                                            {!data.passed && data.prio === "1" && (
                                                                <Typography color="#FF505F">NOT PASSED</Typography>
                                                            )}

                                                            {!data.passed && data.prio === "2" && (
                                                                <Typography color="#FFC107">NOT PASSED</Typography>
                                                            )}
                                                        </Stack>
                                                    </Stack>

                                                    <Container sx={{ marginTop: "20px", marginLeft: "-20px" }}>
                                                        {data.maxScore > 1 && (
                                                            <Stack direction="row">
                                                                <LinearProgress
                                                                    sx={{
                                                                        minWidth: "380px",
                                                                        height: "10px",
                                                                        marginTop: "10px",
                                                                        marginRight: "20px",
                                                                    }}
                                                                    variant="determinate"
                                                                    value={(data.score * 100) / data.maxScore}
                                                                />

                                                                <Typography fontSize="Large">
                                                                    {data.score}/{data.maxScore}
                                                                </Typography>
                                                            </Stack>
                                                        )}
                                                    </Container>
                                                </Grid>

                                                <Divider
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 2,
                                                        marginBottom: 2,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </Container>
                            </Container>


                        </Stack>


                        <div>
                            <Container
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    marginTop: "20px",
                                }}
                            >

                                

                                <Button
                                    onClick={() => {
                                        this.showQualityDialogOpen();
                                    }}
                                    sx={{ width: 150, marginRight: 5 }}
                                >
                                    CANCEL
                                </Button>

                                <Stack direction="row">
                                <Switch
                                    checked = {this.state.forceProvision}
                                    onChange={this.handleForceSwitch}
                                    sx={{marginTop: -1}}
                                />
                                FORCE
                                </Stack>

                                <Button
                                    onClick={(event) => this.provisionDataProduct()}
                                    disabled={!this.state.qualityCheckPassed}
                                    variant="contained"
                                    sx={{ width: 150 }}
                                >
                                    PROVISION
                                </Button>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* Have to be outside flex copntainer to not mess up space around.  */}
                <ETLAccessMenu
                    handleClose={this.handleClose}
                    accessMenuOpen={this.state.accessMenuOpen}
                    anchorAccessMenu={this.state.anchorAccessMenu}
                    dpName={this.props.details["Name"]}
                    token={this.props.token}
                />
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                    }}
                >
                    <Container
                        sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0 !important" }}
                    >
                        <Typography>Lifecycle stage:</Typography>
                        <Chip
                            sx={{ marginLeft: 2 }}
                            // size="small"
                            color={this.props.details.Parameters["dp_state"] == "provisioned" ? "success" : "secondary"}
                            label={this.props.details.Parameters["dp_state"]}
                        />
                    </Container>

                    <Button
                        color="error"
                        variant="contained"
                        sx={{
                            whiteSpace: "nowrap",
                            minWidth: "auto",
                            margin: "5px",
                            width: "100%",
                        }}
                        startIcon={<RocketLaunch />}
                        onClick={(event) => this.showQualityDialogOpen()}
                    >
                        Provision
                    </Button>
                </Container>

                <Dialog
                    open={this.state.openEditSchemaDialog}
                    onClose={() => this.setState({ openEditSchemaDialog: false })}
                    fullWidth={true}
                    maxWidth={"lg"}
                    sx={{
                        ".MuiPaper-root": {
                            padding: 2,
                        },
                    }}
                >
                    <DataProductSchemaEditor dataProductName={this.props.details["Name"]} token={this.props.token} />
                </Dialog>

                <Dialog
                    open={this.state.openEditPolicyDialog}
                    onClose={() => this.setState({ openEditPolicyDialog: false })}
                    fullWidth={true}
                    maxWidth={"lg"}
                    sx={{
                        ".MuiPaper-root": {
                            padding: 2,
                        },
                    }}
                >
                    <PolicyEditor
                        policies={this.state.policies}
                        addNewPolicyRule={this.addNewPolicyRule}
                        setPolicy={(policy) => this.setState({ policies: policy })}
                        token={this.props.token}
                        dpName={this.props.details["Name"]}
                    />
                </Dialog>

                <Dialog
                    open={this.state.openCharacteristicsDialog}
                    onClose={() => this.setState({ openCharacteristicsDialog: false })}
                    fullWidth={true}
                    maxWidth={"lg"}
                    sx={{
                        ".MuiPaper-root": {
                            padding: 4,
                        },
                    }}
                >
                    <Typography variant="h6">Characteristics of the Data Product</Typography>
                    <Divider/>
                    <Attribute  name="Why is this data product Trustworthy?" value="..."/>
                    <Attribute  name="Why is this data product Addressable?" value="..."/>
                    <Attribute  name="..." value="..."/>
                </Dialog>

            </Card>
        );
    }
}

export default MyDataProductCard;
