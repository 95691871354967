/**
 * This component realizes the form that is used by a data product producer to provision a specific product.
 *
 */
import React from "react";
import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Link,
    Container,
} from "@mui/material";
import ReactLoading from "react-loading";
import { Box } from "@mui/system";
// import AceEditor from "react-ace";

// import "ace-builds/src-noconflict/mode-sql";
// import "ace-builds/src-noconflict/theme-tomorrow";
// import "ace-builds/src-noconflict/ext-language_tools";

import config from "../config";
import DataProductService from "../services/DataProductService";

class ProvisionETLForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedProducts: [],
            productSchemas: {},
            dpTypes: {
                "simple-dp": {
                    text: "Simple Data Product",
                    description:
                        "The simple data product consists of the most simplest building blocks to integrate tabular data and provide it without hughe engeineering requirements or sepcial consumption desires.",
                    useCases: ["Simply provide data with good quality", "Integrate existing systems to the mesh"],
                },
                "bi-report-dp": {
                    text: "BI & Report Data Product",
                    description:
                        "With the BI & reporting Data Product, all functionalities will be automatically provided to otpimze the the format for given BI tools and directly set up an environment to work on the visualizations. Moreover, the interfaces are also aligned to the BI consumption.",
                    useCases: ["Create visual reports", "Build production dashboards"],
                },
                "advanced-dp": {
                    text: "Advanced Data Product",
                    description:
                        "The advanced Data Product provides a all things that are needed for handling and working with complex and huge data, e.g. here a Spark cluster is connect to the Data Product environment to do data engineering and analysis with huge data.",
                    useCases: ["Big Data Data Analytics"],
                },
                "advanced-stream-dp": {
                    text: "Advanced Data Product (Stream)",
                    description:
                        "With this type we can create a Data Product that handles stream data, e.g. by using a Kafka Cluster.",
                    useCases: ["Stream Analytics"],
                },
                "ml-dp": {
                    text: "Machine Learning Data Product",
                    description:
                        "THe  Machine Learning Data Prodcut bundles everything that is necessary to integrate source data, do training and experimentation and provide the results of the model in a structures and vaulable manner.",
                    useCases: ["Machine Learning & Advanced Analytics"],
                },
            },
        };
    }

    componentDidMount() {
        this.getDataProducts();
    }

    getDataProducts = () => {
        // console.log(`${config.server.ip}:${config.server.port}/api/getDataProducts`);
        fetch(`http://${config.server.ip}:${config.server.port}/api/getDataProducts`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    dataProducts: json,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleChange = (value, key = undefined) => {
        if (key === undefined) {
            this.setState({
                ...this.state,
                ...{ sourceSystem: value },
            });
        } else if ((key === "includedDataProducts") & !this.state.selectedProducts.includes(value)) {
            this.state.selectedProducts.push(value);
            this.setState({
                ...this.state,
                ...{ selectedProducts: this.state.selectedProducts },
            });

            DataProductService.getDataProductSchema(value).then((schema) => {
                // csonle.log()
                this.state.productSchemas[value] = schema;
                this.setState({
                    ...this.state,
                    ...{ productSchemas: this.state.productSchemas },
                });
            });
        } else {
            this.setState({
                ...this.state,
                ...{ [key]: value },
            });
        }
    };

    handleChangeFileType = (event) => {
        this.setState({
            ...this.state,
            ...{ fileType: event.target.value },
        });
    };

    clickOnType(key) {
        this.setState({ selectedType: key, openDetailsFor: key });
    }

    render() {
        return (
            <Card sx={{ padding: 5 }}>
                <Typography sx={{ fontStyle: "italic" }}>
                    A central aspect of the Data Product idea is to use predefined and resuable building blocks and
                    benefit by this of efficient structures and a simple abstraction from the technology layer.
                </Typography>

                <Typography sx={{ fontStyle: "italic" }}>
                    Please select click on a type to select one and see the details. As soon as you are sure which type
                    works for you click on "Next".
                </Typography>
                <Divider sx={{ marginBottom: 4 }} />

                <Container sx={{ display: "flex", flexDirection: "row", padding: "0px !important" }}>
                    <Container sx={{ padding: "0px !important", display: "flex", flexWrap: "wrap" }}>
                        {Object.keys(this.state.dpTypes).map((key) => (
                            <Button
                                sx={{ width: "150px", height: "150px", margin: 3 }}
                                variant="contained"
                                color={this.state.selectedType === key ? "success" : "primary"}
                                onClick={() => this.clickOnType(key)}
                            >
                                {this.state.dpTypes[key].text}
                            </Button>
                        ))}
                    </Container>
                    {this.state.openDetailsFor !== undefined && (
                        <Container>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                Details for type "{this.state.openDetailsFor}"
                            </Typography>
                            <Typography sx={{ marginBottom: 2 }}>
                                {this.state.dpTypes[this.state.openDetailsFor].description}
                            </Typography>
                            <Typography sx={{ marginBottom: 2 }}>Common use cases might be:</Typography>
                            <ul>
                                {this.state.dpTypes[this.state.openDetailsFor].useCases.map((x) => (
                                    <li>{x}</li>
                                ))}
                            </ul>
                        </Container>
                    )}
                </Container>
                <Button sx={{ float: "right" }} variant={"contained"}>
                    Add Data Product Type
                </Button>
            </Card>
        );
    }
}

export default ProvisionETLForm;
