import { Button, Typography, Menu, MenuItem, Container } from "@mui/material";
import React from "react";
import ReactLoading from "react-loading";
import AuthService from "../services/AuthService";
import * as AWS from "@aws-sdk/client-athena";

const ATHENA_LINK =
    "https://eu-central-1.console.aws.amazon.com/athena/home?region=eu-central-1#/query-editor/";

class ETLAccessMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            accessDialogOpen: false,
            dialogText: "default",
            athenaClient: undefined,
            loading: false,
            credentialState: false
        };

        this.checkAthenaCredentials = this.checkAthenaCredentials.bind(this);
    }

    checkAthenaCredentials = (i, client) => {
        client.config.credentials().then(data =>{
            if(data.accessKeyId) {
                this.setState({...this.state, ...{credentialState: true}})
                clearInterval(i);
            }
        }) 
    }

    componentDidMount = () => {
        AuthService.getAwsCredentials(this.props.token).then((cred) => {
            var client = new AWS.Athena({
                region: "eu-central-1",
                credentials: {
                    accessKeyId: cred.AccessKeyId,
                    secretAccessKey: cred.SecretAccessKey,
                    sessionToken: cred.SessionToken,
                },
            });
            this.setState({ ...this.state, ...{ athenaClient: client } });
            // var counter = 0;
            // var i = setInterval(this.checkAthenaCredentials(i, client), 200);
        });
    };

    showAccessDialogOpen = (dialogText = "default") => {
        this.setState({
            ...this.state,
            ...{
                accessDialogOpen: !this.state.accessDialogOpen,
                dialogText: dialogText,
            },
        });
    };

    createAndStartAthenaSession = async (notebookID) => {
        // Create Athena notebook/workgroup session
        var input = {
            Description: `This is the PySpark notebook for the Data Product ${this.props.dpName}`,
            WorkGroup: "DataMeshWorkgroupSpark",
            NotebookVersion: "Athena notebook version 1",
            EngineConfiguration: {
                MaxConcurrentDpus: 3,
                AdditionalConfigs: {
                    NotebookId: notebookID,
                },
            },
        };
        var res = undefined
        try {
            var command = new AWS.StartSessionCommand(input);
            res = await this.state.athenaClient.send(command);
    
            // Wait until session is created
            input = {
                SessionId: res.SessionId,
            };
            var commandSession = new AWS.GetSessionStatusCommand(input);
            var tempRes = await this.state.athenaClient.send(commandSession);
    
            while (tempRes.Status.State === "CREATED") {
                tempRes = await this.state.athenaClient.send(commandSession);
            }
            return res;
        } catch(err){
            console.log(err)
        } 
        return res
    };

    createAthenaNotebookURL = (sessionId) => {
        const input = {
            SessionId: sessionId, // required
        };
        const commandPreSigned = new AWS.CreatePresignedNotebookUrlCommand(
            input
        );
        this.state.athenaClient
            .send(commandPreSigned)
            .then((response) => {
                console.log("Notebook URL created and opened...");
                this.setLoadingState(false);
                window.open(response.NotebookUrl, "_blank");
            })
            .catch((err) => {
                console.log(err)
            });
    };

    setLoadingState = (state) => {
        this.setState({ ...this.state, ...{ loading: state } });
    };

    openAthenaNotebook = async () => {
        this.props.handleClose("accessMenu")
        this.setLoadingState(true);

        const inputNotebook = {
            WorkGroup: "DataMeshWorkgroupSpark",
            Name: `${this.props.dpName}_etl`,
        };

        // Create notebook, if one already exist just use the existing.
        const commandNoteboook = new AWS.CreateNotebookCommand(inputNotebook);
        this.state.athenaClient
            .send(commandNoteboook)
            .then((res) => {
                // Notebook does not exist
                console.log("Create notebook session...");
                this.createAndStartAthenaSession(res.NotebookId).then((res) => {
                    console.log("Create notebook and access URL...");
                    this.createAthenaNotebookURL(res.SessionId);
                });
            })
            .catch((err) => {
                console.log(err);
                this.state.athenaClient.config
                    .credentials()
                    .then((data) => console.log(data));

                // Notebook already exist
                var input = {
                    Filters: {
                        Name: inputNotebook.Name,
                    },
                    WorkGroup: inputNotebook.WorkGroup,
                };
                const command = new AWS.ListNotebookMetadataCommand(input);
                this.state.athenaClient.send(command).then((res) => {
                    console.log("Notebook already exist....");
                    console.log(res.NotebookMetadataList[0].NotebookId);

                    input = {
                        NotebookId: res.NotebookMetadataList[0].NotebookId,
                    };
                    const command = new AWS.ListNotebookSessionsCommand(input);
                    this.state.athenaClient
                        .send(command)
                        .then((res) => {

                            try{
                                this.createAndStartAthenaSession(input.NotebookId).then((startRes) => {
                                    console.log("Create notebook and access URL...");
                                    console.log(startRes, res.NotebookSessionsList[0].SessionId, res)
                                    this.createAthenaNotebookURL(startRes ? startRes.SessionId : res.NotebookSessionsList[0].SessionId);
                                })
                            } catch(err) {
                                console.log(err)
                                console.log("Create notebook and access URL...");
                                this.createAthenaNotebookURL(
                                    res.NotebookSessionsList[0].SessionId
                                );
                            }
                            
                        })
                        .catch((err) => console.log(err));
                });
            });
    };

    render() {
        return (
            <Container>
                {this.state.loading && (
                    <Container
                        sx={{
                            backgroundColor: "grey",
                            opacity: 0.5,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            zIndex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ReactLoading type={"cylon"} />
                    </Container>
                )}
                <Menu
                    anchorEl={this.props.anchorAccessMenu}
                    open={this.props.accessMenuOpen}
                    onClose={() => this.props.handleClose("accessMenu")}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                            <MenuItem
                                onClick={() =>
                                    console.log("Not implemented yet...")
                                }
                            >
                                Open Athena
                            </MenuItem>
                            <MenuItem onClick={() => this.openAthenaNotebook()}>
                                {this.state.athenaClient === undefined  && <ReactLoading type={"cylon"} />}
                                {this.state.athenaClient !== undefined  && <Typography>Open Jupyter</Typography>}
                            </MenuItem>
                            <MenuItem>Open Glue</MenuItem>

                            <MenuItem onClick={this.props.handleClose}>
                                Access ...
                            </MenuItem>
                </Menu>
            </Container>
        );
    }
}

export default ETLAccessMenu;
