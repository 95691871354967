import React from "react";
import {
    Button,
    ButtonBase,
    Card,
    CardContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import exampleData from "../assets/example_data.json"

class Attribute extends React.Component {
    constructor() {
        super();
        this.state = { dataProducts: [], detailProduct: undefined };

        this.setExampleValue = this.setExampleValue.bind(this)
    }

    setExampleValue() {
        const changeData = { target: { value: exampleData[this.props.name] } }

        this.props.textProps.onChange(changeData)
    }

    render() {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexFlow: "row",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: 2,
                    marginTop: 2,
                }}
            >
                {!this.props.hideName &&
                    <Typography sx={{ flex: 1 }}>{this.props.name}</Typography>
                }

                <TextField

                    disabled
                    style={{
                        flex: 2,
                        marginLeft: this.props.hideName ? 0 : 10,
                    }}
                    size="small"
                    defaultValue={this.props.value}
                    variant="outlined"
                    {...this.props.textProps}
                />

                {!this.props.disableExampleInput && exampleData[this.props.name] &&
                    <IconButton sx={{ marginLeft: "1rem", alignSelf: "center" }} onClick={this.setExampleValue} >
                        <AutoFixHighIcon />
                    </IconButton>

                }
            </Box>
        );
    }
}

export default Attribute;
