import * as React from "react";
import theme from "./theme";
import MainView from "./view/MainView";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AuthService from "./services/AuthService";
import Login from "./components/Login";

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            token: undefined,
            isAuthenticated: false,
            user: undefined,
            // TDOD: make it dynamic
            userDetails: {
                "jhuthmacher": {
                    domain: "Domain A",
                    roles: [
                        "dp-a-owner",
                        "dp-b-read",
                        "dp-c-read-write"
                    ]
                },
                "bdidrich": {
                    domain: "Domain B",
                    roles: [
                        "dp-b-owner",
                        "dp-c-read-write"
                    ]
                }
            }
        };

        this.login = this.login.bind(this);
    }

    componentDidMount() {}

    login(username, password) {
        // TODO: Do error handling
        AuthService.login(username, password).then(data => {
            // As soon as we have a token we check the validity
            AuthService.checkToken(data.token)
                .then((x) => {
                    this.setState({
                        ...this.state,
                        ...{ isAuthenticated: true, token: data.token, access_token: data.access_token, user: username },
                    });
                })
                .catch((x) => {
                    this.setState({
                        ...this.state,
                        ...{ isAuthenticated: false },
                    });
                });
        });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {!this.state.isAuthenticated && <Login login={this.login} />}
                {this.state.isAuthenticated && (
                    <MainView
                        style={{ height: "100vh" }}
                        token={this.state.token}
                        access_token={this.state.access_token}
                        user={this.state.user}
                    />
                )}
            </ThemeProvider>
        );
    }
}

export default App;
